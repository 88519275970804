/*!
Author: Digitec
Author URI: https://www.digitecintl.com/
Version: 1.0.0
*/
* {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@font-face {
  font-family: MontserratarmLight;
  src: url("../fonts/montserrat-armenian/Montserratarm-ExtraLight.otf"); }

@font-face {
  font-family: MontserratarmSemiBold;
  src: url("../fonts/montserrat-armenian/Montserratarm-SemiBold.otf"); }

.dark-bg {
  background-color: #000; }

.global-error {
  text-align: center;
  font-family: "MontserratarmSemiBold";
  font-size: 18px; }

.my-awesome-placeholder .default svg {
  display: block;
  height: auto;
  width: 100%; }
  .my-awesome-placeholder .default svg rect {
    fill: #0e0e0e; }

.home-page-category-placeholder {
  padding: 70px 0px 47px; }
  .home-page-category-placeholder svg rect {
    fill: #efefef; }

input, textarea, select, button {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input:required, input:invalid, textarea:required, textarea:invalid, select:required, select:invalid, button:required, button:invalid {
    box-shadow: none; }

body {
  padding-top: 56px;
  box-sizing: border-box; }
  body.ReactModal__Body--open {
    overflow: hidden; }
  body.mobile-view {
    padding-top: 40px; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

.clear:after, .clear:before {
  clear: both;
  content: '';
  display: table;
  width: 100%; }

.clear-div {
  clear: both; }

.wrapper {
  max-width: 1170px;
  margin: 0 auto;
  width: 96%; }
  .wrapper:after, .wrapper:before {
    clear: both;
    content: '';
    display: table;
    width: 100%; }

.table {
  display: table;
  width: 100%;
  height: 100%; }
  .table .cell {
    display: table-cell;
    vertical-align: middle; }

.side-bar-continer {
  position: absolute;
  left: 25px;
  top: 65px;
  height: 91%;
  -ms-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 256px; }
  .side-bar-continer .side-bar-box {
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    top: 196px; }
    .side-bar-continer .side-bar-box ul {
      padding-bottom: 15px;
      border-bottom: 1px solid #dcdcdc;
      margin-top: 20px; }
      .side-bar-continer .side-bar-box ul:last-child {
        border-bottom: 0px;
        padding-bottom: 0px; }
      .side-bar-continer .side-bar-box ul:first-child {
        margin-top: 0px; }
      .side-bar-continer .side-bar-box ul li {
        margin-bottom: 5px;
        position: relative; }
        .side-bar-continer .side-bar-box ul li a {
          line-height: 44px;
          height: 44px;
          box-sizing: border-box;
          display: block;
          transition: all 0.3s ease-in-out;
          font-family: "MontserratarmSemiBold";
          font-size: 14px;
          color: #000;
          padding: 0px 20px;
          background-color: transparent; }
          .side-bar-continer .side-bar-box ul li a:hover {
            background-color: #F8F5F5; }
          .side-bar-continer .side-bar-box ul li a.active {
            font-family: "MontserratarmSemiBold";
            color: #ED1C24; }
          .side-bar-continer .side-bar-box ul li a.add-button {
            position: absolute;
            right: 0px;
            top: 0px;
            padding-top: 16px; }
            .side-bar-continer .side-bar-box ul li a.add-button:hover {
              background-color: transparent; }
              .side-bar-continer .side-bar-box ul li a.add-button:hover svg path {
                fill: #ED1C24; }
            .side-bar-continer .side-bar-box ul li a.add-button svg {
              display: block;
              width: 9px;
              height: auto; }
    .side-bar-continer .side-bar-box a.button {
      display: block;
      text-align: center;
      margin-top: 45px;
      cursor: pointer;
      height: 50px;
      line-height: 48px;
      width: 100%;
      background-color: #ED1C24;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      border: 0px;
      border-radius: 6px;
      font-size: 20px;
      font-family: "MontserratarmLight";
      color: #fff; }
      .side-bar-continer .side-bar-box a.button:hover {
        opacity: 0.8; }

.commercial-block {
  text-align: center; }
  .commercial-block a {
    display: inline-block; }
  .commercial-block img {
    display: block;
    height: auto;
    width: 100%; }
  .commercial-block svg {
    display: block;
    height: auto;
    width: 100%; }
    .commercial-block svg rect, .commercial-block svg line {
      fill: #efefef; }
    .commercial-block svg rect, .commercial-block svg line {
      stroke: #efefef; }

ul.pagination li {
  float: left;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; }
  ul.pagination li a {
    padding: 3px 12px;
    display: block;
    color: #000;
    font-size: 14px; }
    ul.pagination li a svg {
      padding: 3px 0px;
      display: block;
      width: 5px;
      height: 14px; }
      ul.pagination li a svg path {
        fill: #000; }
  ul.pagination li.disabled a {
    cursor: not-allowed; }
  ul.pagination li:hover {
    background-color: #eee; }
  ul.pagination li.active {
    border: 1px solid #ED1C24; }
    ul.pagination li.active a {
      background-color: #ED1C24;
      color: #fff; }

.cancel-add .ReactModal__Overlay, .report-post .ReactModal__Overlay, .sign-in .ReactModal__Overlay, .prompt .ReactModal__Overlay {
  z-index: 9; }

.cancel-add .ReactModal__Content, .report-post .ReactModal__Content, .sign-in .ReactModal__Content, .prompt .ReactModal__Content {
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  top: 50% !important;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  width: 90%;
  text-align: center;
  padding: 20px 40px !important;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px !important;
  border: 0px !important; }
  .cancel-add .ReactModal__Content .title, .report-post .ReactModal__Content .title, .sign-in .ReactModal__Content .title, .prompt .ReactModal__Content .title {
    font-size: 20px;
    color: #000;
    font-family: "MontserratarmSemiBold";
    margin-bottom: 15px; }
  .cancel-add .ReactModal__Content .description, .report-post .ReactModal__Content .description, .sign-in .ReactModal__Content .description, .prompt .ReactModal__Content .description {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 22px;
    font-family: "MontserratarmLight"; }
  .cancel-add .ReactModal__Content .invalid-feedback, .report-post .ReactModal__Content .invalid-feedback, .sign-in .ReactModal__Content .invalid-feedback, .prompt .ReactModal__Content .invalid-feedback {
    color: #D93837;
    font-size: 14px;
    font-family: "MontserratarmLight"; }
  .cancel-add .ReactModal__Content button, .cancel-add .ReactModal__Content a.button, .report-post .ReactModal__Content button, .report-post .ReactModal__Content a.button, .sign-in .ReactModal__Content button, .sign-in .ReactModal__Content a.button, .prompt .ReactModal__Content button, .prompt .ReactModal__Content a.button {
    margin-bottom: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    width: 130px;
    line-height: 36px;
    height: 38px;
    border-radius: 6px;
    border: 0px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    background: #ED1C24;
    font-family: "MontserratarmLight";
    cursor: pointer; }
    .cancel-add .ReactModal__Content button:last-child, .cancel-add .ReactModal__Content a.button:last-child, .report-post .ReactModal__Content button:last-child, .report-post .ReactModal__Content a.button:last-child, .sign-in .ReactModal__Content button:last-child, .sign-in .ReactModal__Content a.button:last-child, .prompt .ReactModal__Content button:last-child, .prompt .ReactModal__Content a.button:last-child {
      margin-left: 20px; }
    .cancel-add .ReactModal__Content button:hover, .cancel-add .ReactModal__Content a.button:hover, .report-post .ReactModal__Content button:hover, .report-post .ReactModal__Content a.button:hover, .sign-in .ReactModal__Content button:hover, .sign-in .ReactModal__Content a.button:hover, .prompt .ReactModal__Content button:hover, .prompt .ReactModal__Content a.button:hover {
      opacity: .8; }

.change-password-popup .ReactModal__Overlay {
  z-index: 9; }

.change-password-popup .ReactModal__Content {
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  top: 50% !important;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  width: 90%;
  text-align: center;
  padding: 20px 40px !important;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px !important;
  border: 0px !important; }
  .change-password-popup .ReactModal__Content .title {
    font-size: 20px;
    color: #000;
    font-family: "MontserratarmSemiBold";
    margin: 0px 0px 15px; }
  .change-password-popup .ReactModal__Content input {
    width: 100%;
    line-height: 42px;
    height: 42px;
    box-sizing: border-box;
    padding: 0px 18px;
    border: 1px solid  #dcdcdc;
    border-radius: 6px;
    font-size: 15px;
    font-family: "MontserratarmLight";
    color: #000;
    transition: border 0.3s ease-in-out; }
    .change-password-popup .ReactModal__Content input::-webkit-input-placeholder {
      color: #BCBEC0; }
    .change-password-popup .ReactModal__Content input:-moz-placeholder {
      color: #BCBEC0; }
    .change-password-popup .ReactModal__Content input::-moz-placeholder {
      color: #BCBEC0; }
    .change-password-popup .ReactModal__Content input:-ms-input-placeholder {
      color: #BCBEC0; }
    .change-password-popup .ReactModal__Content input.is-invalid {
      border: 1px solid  #D93837; }
  .change-password-popup .ReactModal__Content .row {
    margin-bottom: 40px;
    position: relative; }
  .change-password-popup .ReactModal__Content .description {
    margin: 15px 0px 25px;
    font-size: 16px;
    line-height: 22px;
    font-family: "MontserratarmLight"; }
  .change-password-popup .ReactModal__Content .invalid-feedback {
    color: #D93837;
    font-size: 14px;
    font-family: "MontserratarmLight";
    text-align: left;
    position: absolute;
    bottom: -18px; }
  .change-password-popup .ReactModal__Content button, .change-password-popup .ReactModal__Content a.button {
    margin-bottom: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    width: 130px;
    line-height: 36px;
    height: 38px;
    border-radius: 6px;
    border: 0px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    background: #ED1C24;
    font-family: "MontserratarmLight";
    cursor: pointer; }
    .change-password-popup .ReactModal__Content button:last-child, .change-password-popup .ReactModal__Content a.button:last-child {
      margin-left: 20px; }
    .change-password-popup .ReactModal__Content button:hover, .change-password-popup .ReactModal__Content a.button:hover {
      opacity: .8; }

.custom-link-popup .ReactModal__Overlay {
  z-index: 9; }

.custom-link-popup .ReactModal__Content {
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  top: 50% !important;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  width: 90%;
  text-align: center;
  padding: 20px 40px !important;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px !important;
  border: 0px !important; }
  .custom-link-popup .ReactModal__Content .title {
    font-size: 20px;
    color: #000;
    font-family: "MontserratarmSemiBold";
    margin: 0px 0px 15px; }
  .custom-link-popup .ReactModal__Content input {
    width: 100%;
    line-height: 42px;
    height: 42px;
    box-sizing: border-box;
    padding: 0px 18px;
    border: 1px solid  #dcdcdc;
    border-radius: 6px;
    font-size: 15px;
    font-family: "MontserratarmLight";
    color: #000;
    transition: border 0.3s ease-in-out; }
    .custom-link-popup .ReactModal__Content input::-webkit-input-placeholder {
      color: #BCBEC0; }
    .custom-link-popup .ReactModal__Content input:-moz-placeholder {
      color: #BCBEC0; }
    .custom-link-popup .ReactModal__Content input::-moz-placeholder {
      color: #BCBEC0; }
    .custom-link-popup .ReactModal__Content input:-ms-input-placeholder {
      color: #BCBEC0; }
    .custom-link-popup .ReactModal__Content input.is-invalid {
      border: 1px solid  #D93837; }
  .custom-link-popup .ReactModal__Content .row {
    margin-bottom: 40px;
    position: relative; }
  .custom-link-popup .ReactModal__Content .description {
    margin: 15px 0px 25px;
    font-size: 16px;
    line-height: 22px;
    font-family: "MontserratarmLight"; }
  .custom-link-popup .ReactModal__Content .invalid-feedback {
    color: #D93837;
    font-size: 14px;
    font-family: "MontserratarmLight";
    text-align: left;
    position: absolute;
    bottom: -18px; }
  .custom-link-popup .ReactModal__Content button, .custom-link-popup .ReactModal__Content a.button {
    margin-bottom: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    width: 130px;
    line-height: 36px;
    height: 38px;
    border-radius: 6px;
    border: 0px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    background: #ED1C24;
    font-family: "MontserratarmLight";
    cursor: pointer; }
    .custom-link-popup .ReactModal__Content button:last-child, .custom-link-popup .ReactModal__Content a.button:last-child {
      margin-left: 20px; }
    .custom-link-popup .ReactModal__Content button:hover, .custom-link-popup .ReactModal__Content a.button:hover {
      opacity: .8; }

.report-post .ReactModal__Content .report-items-list {
  text-align: left; }
  .report-post .ReactModal__Content .report-items-list .item {
    font-size: 14px;
    font-family: "MontserratarmLight";
    margin-bottom: 10px;
    position: relative; }
    .report-post .ReactModal__Content .report-items-list .item:last-child {
      margin-bottom: 0px; }
    .report-post .ReactModal__Content .report-items-list .item input, .report-post .ReactModal__Content .report-items-list .item label {
      display: inline-block;
      vertical-align: middle; }
    .report-post .ReactModal__Content .report-items-list .item input[type=radio].css-checkbox {
      position: absolute;
      width: 100%;
      cursor: pointer;
      opacity: 0; }
    .report-post .ReactModal__Content .report-items-list .item input[type=radio].css-checkbox + label.css-label {
      padding-left: 20px;
      height: 12px;
      display: inline-block;
      line-height: 12px;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 14px;
      font-family: "MontserratarmLight";
      vertical-align: middle;
      cursor: pointer; }
    .report-post .ReactModal__Content .report-items-list .item input[type=radio].css-checkbox:checked + label.css-label {
      background-position: 0 -12px; }
    .report-post .ReactModal__Content .report-items-list .item label.css-label {
      background-image: url(../images/radio-button.png);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }

.loader-box {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #fff;
  z-index: 999999; }
  .loader-box .loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    border: 2px solid #ED1C24;
    top: 50%;
    animation: loader 2s infinite ease; }
    .loader-box .loader .loader-inner {
      vertical-align: top;
      display: inline-block;
      width: 100%;
      background-color: #ED1C24;
      animation: loader-inner 2s infinite ease-in; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(180deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader-inner {
  0% {
    height: 0%; }
  25% {
    height: 0%; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0%; } }

.react-select.is-invalid .react-select__control {
  border: 1px solid #eb1c24 !important; }

.react-select .react-select__control {
  background-color: #fff !important;
  cursor: pointer !important;
  width: 100% !important;
  height: 42px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  padding: 0px 0px 0px 15px !important;
  border: 1px solid #DDDDDD !important;
  border-radius: 6px !important;
  font-size: 15px !important;
  font-family: "MontserratarmLight";
  color: #000 !important; }
  .react-select .react-select__control.open {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
  .react-select .react-select__control input {
    height: auto !important;
    line-height: initial !important; }
  .react-select .react-select__control:focus, .react-select .react-select__control:focus-within {
    box-shadow: none !important; }
  .react-select .react-select__control .react-dropdown-select-item-selected {
    border-bottom: 0px !important;
    background: #E2E2E2 !important;
    color: #fff !important; }
  .react-select .react-select__control .react-select__value-container {
    padding: 0px !important;
    height: 100%; }
    .react-select .react-select__control .react-select__value-container .react-select__placeholder {
      color: #BCBEC0; }

.react-select .react-select__menu {
  font-family: "MontserratarmLight";
  font-size: 15px;
  overflow: hidden; }
  .react-select .react-select__menu .react-select__menu-list {
    padding: 0px; }
    .react-select .react-select__menu .react-select__menu-list .react-select__option {
      padding: 10px 15px !important;
      background-color: transparent;
      cursor: pointer; }
      .react-select .react-select__menu .react-select__menu-list .react-select__option:hover {
        background: #eb1c24 !important;
        color: #fff !important; }
    .react-select .react-select__menu .react-select__menu-list .react-select__option--is-selected {
      background: #eb1c24 !important;
      color: #fff !important; }

.Dropdown-root {
  position: relative;
  margin-bottom: 40px; }
  .Dropdown-root.is-invalid .Dropdown-control {
    border: 1px solid  #D93837; }

.Dropdown-control {
  cursor: pointer;
  width: 100%;
  line-height: 42px;
  height: 42px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 18px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  font-size: 15px;
  font-family: "MontserratarmLight";
  color: #000;
  -webkit-transition: border 0.3s ease-in-out;
  -o-transition: border 0.3s ease-in-out;
  transition: border 0.3s ease-in-out; }

.Dropdown-placeholder {
  color: #BCBEC0; }
  .Dropdown-placeholder.is-selected {
    color: #000; }

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 20px;
  width: 0; }

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px; }

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch; }

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: #333333;
  font-weight: bold;
  text-transform: capitalize; }

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 18px;
  font-family: "MontserratarmLight"; }

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.Dropdown-option:hover {
  background-color: #eb1c24;
  color: #fff; }

.Dropdown-option.is-selected {
  background-color: #eb1c24;
  color: #fff; }

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px; }

@media screen and (max-width: 1680px) {
  body {
    padding-top: 50px; } }

@media screen and (max-width: 1440px) {
  .react-select.is-invalid .react-select__control {
    border: 1px solid #eb1c24 !important; }
  .react-select .react-select__control {
    height: 38px !important;
    font-size: 13px !important;
    line-height: 38px !important; }
    .react-select .react-select__control .react-select__value-container {
      height: 100%; }
  .react-select .react-select__menu {
    font-size: 13px; }
  .Dropdown-control {
    height: 38px;
    line-height: 38px;
    font-size: 13px; }
  .Dropdown-root {
    margin-bottom: 35px; }
  .Dropdown-option {
    font-size: 13px; }
  .change-password-popup .ReactModal__Content, .custom-link-popup .ReactModal__Content, .cancel-add .ReactModal__Content, .report-post .ReactModal__Content {
    padding: 20px !important;
    box-sizing: border-box; }
    .change-password-popup .ReactModal__Content .title, .custom-link-popup .ReactModal__Content .title, .cancel-add .ReactModal__Content .title, .report-post .ReactModal__Content .title {
      font-size: 18px; }
    .change-password-popup .ReactModal__Content .row, .custom-link-popup .ReactModal__Content .row, .cancel-add .ReactModal__Content .row, .report-post .ReactModal__Content .row {
      margin-bottom: 30px; }
    .change-password-popup .ReactModal__Content .description, .custom-link-popup .ReactModal__Content .description, .cancel-add .ReactModal__Content .description, .report-post .ReactModal__Content .description {
      margin: 10px 0px 25px;
      font-size: 14px;
      line-height: 21px; }
    .change-password-popup .ReactModal__Content input, .custom-link-popup .ReactModal__Content input, .cancel-add .ReactModal__Content input, .report-post .ReactModal__Content input {
      line-height: 38px;
      height: 38px;
      font-size: 13px; }
    .change-password-popup .ReactModal__Content .invalid-feedback, .custom-link-popup .ReactModal__Content .invalid-feedback, .cancel-add .ReactModal__Content .invalid-feedback, .report-post .ReactModal__Content .invalid-feedback {
      font-size: 12px; }
    .change-password-popup .ReactModal__Content button, .change-password-popup .ReactModal__Content a.button, .custom-link-popup .ReactModal__Content button, .custom-link-popup .ReactModal__Content a.button, .cancel-add .ReactModal__Content button, .cancel-add .ReactModal__Content a.button, .report-post .ReactModal__Content button, .report-post .ReactModal__Content a.button {
      font-size: 14px; }
  .side-bar-continer {
    width: 220px; }
    .side-bar-continer .side-bar-box ul {
      margin-top: 10px; }
      .side-bar-continer .side-bar-box ul li {
        margin-bottom: 0px; }
        .side-bar-continer .side-bar-box ul li a {
          line-height: 40px;
          height: 40px;
          font-size: 13px; } }

@media screen and (max-width: 768px) {
  .side-bar-continer {
    display: none; } }

@media screen and (max-width: 520px) {
  .change-password-popup .ReactModal__Content button, .change-password-popup .ReactModal__Content a.button, .custom-link-popup .ReactModal__Content button, .custom-link-popup .ReactModal__Content a.button, .cancel-add .ReactModal__Content button, .cancel-add .ReactModal__Content a.button, .report-post .ReactModal__Content button, .report-post .ReactModal__Content a.button {
    height: 36px;
    line-height: 36px;
    padding: 0px;
    width: 113px; } }

body.mobile-view .home-container .pined-item-container .default .image-box:hover:after {
  content: inherit; }

header {
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.11); }
  header:after, header:before {
    display: table;
    content: '';
    width: 100%;
    clear: both; }
  header.invert {
    background: #000; }
    header.invert .mobile .mobile-menu-wrap {
      background: #000; }
  header nav,
  header nav ul,
  header nav ul li {
    margin: 0;
    padding: 0; }
  header .left {
    float: left;
    box-sizing: border-box;
    padding-left: 25px; }
  header .right {
    float: right;
    box-sizing: border-box;
    padding-right: 25px; }
  header .logo, header nav, header .live-menu-item {
    display: inline-block;
    vertical-align: middle; }
  header .logo .lamedia_logo {
    width: 100%;
    max-width: 150px; }
  header .logo.invert-color .cls-1 {
    fill: #fff; }
  header .logo.invert-color .cls-2 {
    fill: #ed1c24; }
  header nav {
    margin-left: 192px; }
    header nav ul li {
      position: relative;
      font-family: "MontserratarmSemiBold";
      font-size: 14px;
      display: inline-block;
      vertical-align: top; }
      header nav ul li a {
        padding: 18.5px 20px;
        display: inline-block; }
  header .live-menu-item li {
    position: relative;
    font-family: "MontserratarmSemiBold";
    font-size: 14px;
    color: #000;
    display: inline-block;
    vertical-align: top; }
    header .live-menu-item li a {
      padding: 18.5px 20px 18.5px 30px;
      display: inline-block;
      color: #b5b4b4;
      position: relative; }
      header .live-menu-item li a:after {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #b5b4b4;
        border-radius: 5px;
        top: 50%;
        left: 15px;
        content: '';
        margin-top: -3px; }
      header .live-menu-item li a[data-status="live"] {
        color: #000; }
        header .live-menu-item li a[data-status="live"]:after {
          background-color: #eb1c24; }
      header .live-menu-item li a.active {
        background-color: #eb1c24;
        color: #fff; }
        header .live-menu-item li a.active:after {
          background-color: #fff; }
    header .live-menu-item li:after {
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background-color: #eb1c24;
      transition: height .3s ease-in-out; }
    header .live-menu-item li:hover {
      background-color: #eb1c24; }
      header .live-menu-item li:hover a {
        color: #fff; }
        header .live-menu-item li:hover a:after {
          background-color: #fff; }
  header .search-box {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-left: 30px;
    width: 260px; }
    header .search-box input {
      font-size: 14px;
      font-family: "MontserratarmLight";
      padding: 0px 35px 0px 15px;
      box-sizing: border-box;
      height: 30px;
      width: 100%;
      border: 0px;
      border-radius: 30px;
      background: #F7F7F7;
      border: 1px solid transparent; }
      header .search-box input.error {
        border: 1px solid #D93837; }
    header .search-box .search-icon {
      right: 9px;
      position: absolute;
      padding: 5px;
      top: 5px; }
      header .search-box .search-icon img {
        display: block;
        width: 10px;
        height: auto;
        cursor: pointer; }
    header .search-box .invalid-feedback {
      position: absolute;
      color: #D93837;
      font-size: 12px;
      top: 28px;
      font-family: "MontserratarmLight"; }
  header .langBox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 45px; }
    header .langBox .selected-lang {
      cursor: pointer;
      display: flex;
      align-items: center; }
      header .langBox .selected-lang .icon-box svg {
        display: block;
        width: 17px;
        height: 17px; }
        header .langBox .selected-lang .icon-box svg .cls-1 {
          fill: none;
          stroke: #000;
          stroke-miterlimit: 10; }
      header .langBox .selected-lang .lang-code {
        margin-left: 6px;
        font-size: 13px;
        font-family: "MontserratarmSemiBold";
        margin-top: -3px; }
      header .langBox .selected-lang:hover {
        opacity: .7; }
    header .langBox .lang-tool-tip {
      position: absolute;
      background-color: #fff;
      width: 150px;
      right: 0px;
      top: 42px;
      -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      display: none; }
      header .langBox .lang-tool-tip.show {
        display: block; }
      header .langBox .lang-tool-tip div {
        display: block;
        color: #000;
        font-size: 13px;
        font-family: "MontserratarmLight";
        cursor: pointer;
        padding: 14px 25px 16px; }
        header .langBox .lang-tool-tip div:hover {
          opacity: .7; }
  header .currencyBox {
    display: inline-block;
    vertical-align: middle; }
    header .currencyBox ul li {
      display: inline-block;
      vertical-align: top;
      line-height: 54px;
      font-family: "MontserratarmLight";
      font-size: 14px;
      margin-right: 10px; }
      header .currencyBox ul li:last-child {
        margin-right: 0px; }
      header .currencyBox ul li > span {
        display: inline-block;
        vertical-align: middle; }
        header .currencyBox ul li > span.name {
          color: #000; }
        header .currencyBox ul li > span.value {
          color: #eb1c24; }
  header .button-box {
    margin-left: 45px;
    display: inline-block;
    vertical-align: middle;
    line-height: 50px; }
    header .button-box a {
      color: #000;
      font-size: 14px;
      font-family: "MontserratarmSemiBold";
      opacity: 1;
      padding: 5px 10px; }
      header .button-box a:hover {
        opacity: .7; }
  header .user-box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px; }
    header .user-box .full-name {
      cursor: pointer;
      padding: 5px 10px;
      font-size: 13px;
      font-family: "MontserratarmSemiBold";
      margin-top: -3px; }
      header .user-box .full-name img {
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: auto; }
      header .user-box .full-name:hover {
        opacity: .7; }
    header .user-box .user-tool-tip {
      position: absolute;
      background-color: #fff;
      width: 210px;
      right: 0px;
      top: 45px;
      -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      display: none; }
      header .user-box .user-tool-tip.show {
        display: block; }
      header .user-box .user-tool-tip .box {
        border-bottom: 1px solid #ccc;
        padding: 24px 25px 26px; }
        header .user-box .user-tool-tip .box a:last-child {
          margin-bottom: 0px; }
        header .user-box .user-tool-tip .box:last-child {
          border-bottom: 0px; }
      header .user-box .user-tool-tip a {
        margin-bottom: 15px;
        display: block;
        color: #000;
        font-size: 13px;
        font-family: "MontserratarmLight"; }
        header .user-box .user-tool-tip a:hover {
          opacity: .7; }
      header .user-box .user-tool-tip span {
        cursor: pointer;
        margin-top: 26px;
        display: block;
        width: 100%;
        background-color: #eb1c24;
        color: #fff;
        font-size: 14px;
        border-radius: 6px;
        line-height: 30px;
        height: 30px;
        font-family: "MontserratarmSemiBold";
        text-align: center;
        opacity: 1; }
        header .user-box .user-tool-tip span:hover {
          opacity: .8; }
  header .menu-burger {
    margin-top: 12px;
    cursor: pointer; }
    header .menu-burger i {
      display: block;
      cursor: pointer;
      width: 20px;
      height: 16px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out; }
      header .menu-burger i span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #000;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out; }
        header .menu-burger i span:nth-child(1) {
          top: 0px; }
        header .menu-burger i span:nth-child(2) {
          top: 6px; }
        header .menu-burger i span:nth-child(3) {
          top: 6px; }
        header .menu-burger i span:nth-child(4) {
          top: 12px; }
    header .menu-burger.clicked i span {
      background: #fff; }
      header .menu-burger.clicked i span:nth-child(1) {
        width: 0%;
        left: 50%;
        opacity: 0; }
      header .menu-burger.clicked i span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .menu-burger.clicked i span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      header .menu-burger.clicked i span:nth-child(4) {
        width: 0%;
        left: 50%;
        opacity: 0; }
  header .mobile {
    position: relative;
    z-index: 2; }
    header .mobile .mobile-menu-wrap {
      z-index: 3;
      background: #fff;
      position: relative; }
    header .mobile .logo {
      padding: 11.5px 0px; }
    header .mobile .search-box {
      position: absolute;
      margin: 0px auto;
      width: calc(100% - 50px);
      left: 0px;
      right: 0px;
      transition: bottom .3s ease-in-out; }
      header .mobile .search-box.show {
        bottom: -35px; }
      header .mobile .search-box.hide {
        bottom: 5px; }
  header .mobile-menu {
    z-index: 2;
    position: fixed;
    background: #000;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    margin-top: 40px;
    top: 0px; }
    header .mobile-menu .wrapper {
      max-width: 260px;
      width: 96%;
      margin: 15px auto 0px; }
    header .mobile-menu .button-box {
      margin: 0px auto;
      display: block;
      text-align: left;
      border-bottom: 1px solid #484848;
      padding-bottom: 10px;
      margin-bottom: 10px;
      line-height: inherit; }
      header .mobile-menu .button-box a {
        color: #fff;
        font-size: 12px;
        padding: 5px 0px; }
    header .mobile-menu .user-box {
      display: block;
      text-align: left;
      border-bottom: 1px solid #484848;
      padding-bottom: 10px;
      margin-bottom: 10px;
      margin-left: 0px; }
      header .mobile-menu .user-box .full-name {
        line-height: inherit;
        color: #fff;
        padding: 5px 30px 5px 0px;
        position: relative; }
        header .mobile-menu .user-box .full-name img {
          display: none; }
        header .mobile-menu .user-box .full-name:hover {
          opacity: 1; }
        header .mobile-menu .user-box .full-name svg {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 11px;
          padding: 7px 10px 7px 7px;
          height: auto;
          display: block; }
          header .mobile-menu .user-box .full-name svg path {
            fill: #fff; }
      header .mobile-menu .user-box .user-tool-tip {
        box-sizing: border-box;
        display: block;
        position: fixed;
        left: -210px;
        top: 0px;
        right: inherit;
        height: 100%;
        z-index: 1;
        transition: left .3s ease-in-out; }
        header .mobile-menu .user-box .user-tool-tip.show {
          left: 0px; }
    header .mobile-menu .live-menu-item {
      display: block; }
      header .mobile-menu .live-menu-item li {
        display: block; }
        header .mobile-menu .live-menu-item li a[data-status="live"] {
          padding: 5px 0 5px 16px;
          color: #fff;
          display: block; }
        header .mobile-menu .live-menu-item li a:after {
          margin-top: -2px;
          left: 0px; }
        header .mobile-menu .live-menu-item li a.active {
          background: transparent; }
          header .mobile-menu .live-menu-item li a.active:after {
            background-color: #eb1c24; }
    header .mobile-menu nav {
      margin: 0px;
      display: block;
      width: 100%; }
      header .mobile-menu nav ul {
        padding: 0px; }
        header .mobile-menu nav ul.rc-menu {
          box-shadow: none; }
          header .mobile-menu nav ul.rc-menu .rc-menu-item {
            padding: 0px;
            padding-left: 0px !important;
            margin-bottom: 0px;
            background-color: transparent !important; }
            header .mobile-menu nav ul.rc-menu .rc-menu-item a {
              padding: 5px 0px; }
          header .mobile-menu nav ul.rc-menu.rc-menu-inline .rc-menu-submenu-arrow {
            color: #fff;
            top: 0px;
            z-index: 2;
            line-height: inherit;
            right: 0px;
            padding: 12px 13px !important; }
          header .mobile-menu nav ul.rc-menu.rc-menu-inline .rc-menu-item-active > .rc-menu-submenu-title, header .mobile-menu nav ul.rc-menu.rc-menu-inline .rc-menu-submenu-active > .rc-menu-submenu-title {
            background-color: transparent; }
          header .mobile-menu nav ul.rc-menu .rc-menu-submenu > .rc-menu-submenu-title {
            padding: 0px;
            padding-left: 0px !important; }
          header .mobile-menu nav ul.rc-menu.rc-menu-sub.rc-menu-inline {
            background-color: transparent;
            box-sizing: border-box;
            padding-left: 20px; }
          header .mobile-menu nav ul.rc-menu > li.rc-menu-submenu {
            margin-bottom: 0px; }
          header .mobile-menu nav ul.rc-menu ul.rc-menu li {
            background-color: transparent !important;
            margin-bottom: 0px !important; }
            header .mobile-menu nav ul.rc-menu ul.rc-menu li a {
              padding: 3px 0px;
              font-family: "MontserratarmLight"; }
            header .mobile-menu nav ul.rc-menu ul.rc-menu li .rc-menu-submenu-title a {
              padding: 3px 0px;
              font-family: "MontserratarmLight"; }
            header .mobile-menu nav ul.rc-menu ul.rc-menu li .rc-menu-submenu-title .rc-menu-submenu-arrow {
              padding: 5px 13px !important; }
        header .mobile-menu nav ul > li {
          margin-bottom: 10px !important; }
        header .mobile-menu nav ul li {
          display: block;
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px; }
          header .mobile-menu nav ul li:last-child {
            margin-bottom: 0; }
          header .mobile-menu nav ul li span {
            display: block;
            width: 100%; }
          header .mobile-menu nav ul li a {
            display: block;
            padding: 0px;
            color: #fff;
            padding: 10px 0px; }
    header .mobile-menu .langBox {
      margin-left: 0px;
      border-top: 1px solid #484848;
      padding-top: 10px;
      margin-top: 10px;
      width: 100%;
      cursor: pointer; }
      header .mobile-menu .langBox .selected-lang {
        position: relative; }
        header .mobile-menu .langBox .selected-lang span {
          display: block;
          color: #fff;
          font-size: 12px;
          font-family: "MontserratarmSemiBold";
          padding: 10px 0px;
          width: 100%;
          position: relative;
          z-index: 2; }
        header .mobile-menu .langBox .selected-lang .rc-menu-submenu-arrow {
          position: absolute;
          color: #fff;
          top: 0px;
          z-index: 1;
          line-height: inherit;
          right: 0px;
          padding: 12px 13px !important;
          font: normal normal normal 14px/1 FontAwesome;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -webkit-transition: -webkit-transform .3s;
          transition: -webkit-transform .3s;
          transition: transform .3s;
          transition: transform .3s, -webkit-transform .3s; }
          header .mobile-menu .langBox .selected-lang .rc-menu-submenu-arrow:before {
            content: "\f0da"; }
        header .mobile-menu .langBox .selected-lang:hover {
          opacity: 1; }
        header .mobile-menu .langBox .selected-lang.open .rc-menu-submenu-arrow {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }
      header .mobile-menu .langBox .lang-tool-tip {
        position: relative;
        width: 100%;
        top: initial;
        background: transparent; }
        header .mobile-menu .langBox .lang-tool-tip div {
          font-size: 13px;
          font-family: "MontserratarmLight";
          padding: 3px 0px;
          color: #fff;
          padding-left: 20px; }

@media screen and (max-width: 1680px) {
  header nav {
    margin-left: 60px; }
    header nav ul li {
      font-size: 13px; }
      header nav ul li a {
        padding: 16.5px 18px; }
  header .live-menu-item li {
    font-size: 13px; }
    header .live-menu-item li a {
      padding: 16.5px 18px 16.5px 26px; }
      header .live-menu-item li a:after {
        margin-top: -1px;
        width: 6px;
        height: 6px; }
  header .search-box input {
    font-size: 12px;
    height: 28px; }
  header .search-box .search-icon {
    top: 4px; }
    header .search-box .search-icon img {
      width: 9px; }
  header .logo .lamedia_logo {
    max-width: 110px; }
  header .currencyBox ul li {
    font-size: 13px;
    line-height: 48px; }
  header .user-box {
    margin-left: 35px; }
    header .user-box .full-name {
      font-size: 12px; }
    header .user-box .user-tool-tip {
      top: 40px; }
      header .user-box .user-tool-tip a {
        font-size: 12px; }
      header .user-box .user-tool-tip .box {
        padding: 16px 20px 18px; } }

@media screen and (max-width: 1270px) {
  header .currencyBox {
    display: none; }
  header .user-box {
    margin: 0px; }
    header .user-box .full-name {
      line-height: 40px; }
    header .user-box .user-tool-tip {
      top: 52px; } }

@media screen and (max-width: 1024px) {
  header .mobile .logo {
    padding: 11.5px 0px; }
    header .mobile .logo .lamedia_logo {
      max-width: 111.2px; } }

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/FontAwesome/fontawesome-webfont.eot");
  src: url("../fonts/FontAwesome/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fontawesome-webfont.woff") format("woff"), url("../fonts/FontAwesome/fontawesome-webfont.ttf") format("truetype"), url("../fonts/FontAwesome/fontawesome-webfont.svg?#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.rc-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-menu-hidden {
  display: none; }

.rc-menu-collapse {
  overflow: hidden; }

.rc-menu-collapse-active {
  transition: height 0.3s ease-out; }

.rc-menu-item-group-list {
  margin: 0;
  padding: 0; }

.rc-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede; }

.rc-menu-item-selected {
  background-color: #eb1c24;
  transform: translateZ(0); }

.rc-menu-submenu-selected {
  background-color: #eb1c24; }

.rc-menu > li.rc-menu-submenu {
  padding: 0; }

.rc-menu-horizontal.rc-menu-sub,
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  min-width: 160px;
  margin-top: 0; }

.rc-menu-item,
.rc-menu-submenu-title {
  margin: 0;
  position: relative;
  display: block;
  white-space: nowrap; }

.rc-menu-item.rc-menu-item-disabled,
.rc-menu-submenu-title.rc-menu-item-disabled,
.rc-menu-item.rc-menu-submenu-disabled,
.rc-menu-submenu-title.rc-menu-submenu-disabled {
  color: #777 !important; }

.rc-menu > .rc-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  background-color: #eb1c24; }

.rc-menu-submenu-popup {
  position: absolute;
  z-index: 9; }

.rc-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px; }

.rc-menu-submenu > .rc-menu {
  background-color: #fff; }
  .rc-menu-submenu > .rc-menu a {
    font-family: "MontserratarmSemiBold";
    font-size: 13px; }

.rc-menu .rc-menu-submenu-title .anticon,
.rc-menu .rc-menu-item .anticon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  top: -1px; }

.rc-menu-horizontal {
  border: none;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  background: transparent;
  border-bottom: 0px;
  border-radius: 0px; }

.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: #eb1c24; }
  .rc-menu-item-active a,
  .rc-menu-submenu-active > .rc-menu-submenu-title a {
    color: #fff; }

.rc-menu-item a {
  color: #000; }

.rc-menu-item.rc-menu-item-active a {
  color: #fff; }

.rc-menu-submenu a {
  color: #000; }

.rc-menu-horizontal > .rc-menu-item a,
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title a {
  color: #000; }

.rc-menu-horizontal > .rc-menu-item-active a,
.rc-menu-horizontal > .rc-menu-submenu-active > .rc-menu-submenu-title a {
  color: #fff; }

.rc-menu-horizontal > .rc-menu-submenu,
.rc-menu-horizontal > .rc-menu-item {
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 0px; }

.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  background-color: #eb1c24; }
  .rc-menu-horizontal > .rc-menu-submenu-active a,
  .rc-menu-horizontal > .rc-menu-item-active a {
    color: #fff; }

.rc-menu-horizontal:after {
  content: "\20";
  display: block;
  height: 0;
  clear: both; }

.rc-menu-vertical,
.rc-menu-vertical-left,
.rc-menu-vertical-right,
.rc-menu-inline {
  padding: 12px 0; }

.rc-menu-vertical > .rc-menu-item,
.rc-menu-vertical-left > .rc-menu-item,
.rc-menu-vertical-right > .rc-menu-item,
.rc-menu-inline > .rc-menu-item,
.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 8px 24px 9px 24px; }

.rc-menu-vertical .rc-menu-submenu-arrow,
.rc-menu-vertical-left .rc-menu-submenu-arrow,
.rc-menu-vertical-right .rc-menu-submenu-arrow,
.rc-menu-inline .rc-menu-submenu-arrow {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: 16px;
  line-height: 21px;
  font-size: 13px; }

.rc-menu-submenu-active > .rc-menu-submenu-title i {
  color: #fff; }

.rc-menu-vertical .rc-menu-submenu-arrow:before,
.rc-menu-vertical-left .rc-menu-submenu-arrow:before,
.rc-menu-vertical-right .rc-menu-submenu-arrow:before,
.rc-menu-inline .rc-menu-submenu-arrow:before {
  content: "\f0da"; }

.rc-menu-inline .rc-menu-submenu-arrow {
  transform: rotate(90deg);
  transition: transform .3s; }

.rc-menu-inline .rc-menu-submenu-open > .rc-menu-submenu-title .rc-menu-submenu-arrow {
  transform: rotate(-90deg); }

.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  padding: 0; }

.rc-menu-sub.rc-menu-inline {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none; }

.rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 0; }

.rc-menu-open-slide-up-enter,
.rc-menu-open-slide-up-appear {
  animation-duration: .3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused; }

.rc-menu-open-slide-up-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused; }

.rc-menu-open-slide-up-enter.rc-menu-open-slide-up-enter-active,
.rc-menu-open-slide-up-appear.rc-menu-open-slide-up-appear-active {
  animation-name: rcMenuOpenSlideUpIn;
  animation-play-state: running; }

.rc-menu-open-slide-up-leave.rc-menu-open-slide-up-leave-active {
  animation-name: rcMenuOpenSlideUpOut;
  animation-play-state: running; }

@keyframes rcMenuOpenSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0); }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1); } }

@keyframes rcMenuOpenSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1); }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0); } }

.rc-menu-open-zoom-enter,
.rc-menu-open-zoom-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused; }

.rc-menu-open-zoom-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused; }

.rc-menu-open-zoom-enter.rc-menu-open-zoom-enter-active,
.rc-menu-open-zoom-appear.rc-menu-open-zoom-appear-active {
  animation-name: rcMenuOpenZoomIn;
  animation-play-state: running; }

.rc-menu-open-zoom-leave.rc-menu-open-zoom-leave-active {
  animation-name: rcMenuOpenZoomOut;
  animation-play-state: running; }

@keyframes rcMenuOpenZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0); }
  100% {
    opacity: 1;
    transform: scale(1, 1); } }

@keyframes rcMenuOpenZoomOut {
  0% {
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    transform: scale(0, 0); } }

footer {
  background-color: #000;
  padding: 35px 0px; }
  footer .footer-wrapper {
    max-width: 1200px;
    width: 96%;
    box-sizing: border-box;
    margin: 0 auto; }
  footer .item {
    display: inline-block;
    vertical-align: top; }
    footer .item.col-1 {
      max-width: 450px;
      width: 38%;
      margin-right: 9%; }
      footer .item.col-1 .logo a {
        display: inline-block; }
      footer .item.col-1 .logo img {
        max-width: 112px;
        display: block;
        height: auto;
        width: 100%; }
      footer .item.col-1 .logo .footer_logo {
        width: 100%; }
      footer .item.col-1 .text {
        margin: 20px 0px 30px;
        font-size: 13px;
        font-family: "MontserratarmLight";
        line-height: 26px;
        color: #fff;
        letter-spacing: 0.02em; }
    footer .item.menu {
      width: 195px;
      margin-right: 25px; }
      footer .item.menu:last-child {
        margin-right: 0px; }
      footer .item.menu li {
        margin-bottom: 16px; }
        footer .item.menu li:last-child {
          margin-bottom: 0px; }
        footer .item.menu li a {
          font-size: 13px;
          font-family: "MontserratarmLight";
          color: #fff; }
  footer .bottom {
    margin-top: 40px; }
    footer .bottom .social {
      float: right;
      width: 202px; }
      footer .bottom .social .title {
        font-size: 13px;
        font-family: "MontserratarmLight";
        color: #fff;
        margin-bottom: 5px; }
      footer .bottom .social .icons a {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px; }
        footer .bottom .social .icons a:last-child {
          margin-right: 0px; }
        footer .bottom .social .icons a svg {
          display: block;
          width: auto;
          height: 28px; }
          footer .bottom .social .icons a svg .cls-2 {
            fill: #fff; }
    footer .bottom .copy-right {
      margin-top: 10px;
      float: left;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #fff; }

@media screen and (max-width: 1270px) {
  footer .footer-wrapper {
    width: 90%; }
  footer .bottom .copy-right {
    font-size: 12px; }
  footer .bottom .social {
    width: 195px; }
  footer .item.col-1 {
    margin: 0px;
    float: left;
    width: 60%;
    max-width: inherit; }
    footer .item.col-1 .text {
      font-size: 12px;
      line-height: 22px; }
  footer .item.col-4 {
    float: right; }
  footer .item.col-2, footer .item.col-3 {
    display: none; }
  footer .item.menu li {
    margin-bottom: 12px; }
    footer .item.menu li a {
      font-size: 12px; } }

@media screen and (max-width: 760px) {
  footer .item.col-1, footer .item.col-4 {
    float: none;
    width: 100%;
    text-align: center; }
  footer .item.col-1 {
    margin-bottom: 25px; }
  footer .bottom .social, footer .bottom .copy-right {
    float: none;
    width: 100%;
    text-align: center; }
  footer .bottom .social {
    margin-bottom: 35px; } }

.sign-up-container, .sign-in-container, .forgot-password-container, .reset-password-container {
  max-width: 600px;
  width: 90%;
  margin: 0px auto; }
  .sign-up-container > .table, .sign-in-container > .table, .forgot-password-container > .table, .reset-password-container > .table {
    box-sizing: border-box;
    height: calc(100vh - 471px);
    padding: 50px 0px;
    text-align: center; }
  .sign-up-container h1, .sign-up-container .section-title, .sign-in-container h1, .sign-in-container .section-title, .forgot-password-container h1, .forgot-password-container .section-title, .reset-password-container h1, .reset-password-container .section-title {
    font-family: "MontserratarmLight";
    font-size: 26px;
    color: #000;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
    .sign-up-container h1.no-margin, .sign-up-container .section-title.no-margin, .sign-in-container h1.no-margin, .sign-in-container .section-title.no-margin, .forgot-password-container h1.no-margin, .forgot-password-container .section-title.no-margin, .reset-password-container h1.no-margin, .reset-password-container .section-title.no-margin {
      margin-bottom: 0px; }
  .sign-up-container .section-description, .sign-in-container .section-description, .forgot-password-container .section-description, .reset-password-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 0px 0px 40px;
    text-align: center; }
  .sign-up-container .button, .sign-in-container .button, .forgot-password-container .button, .reset-password-container .button {
    border: 0px;
    border-radius: 6px;
    padding: 0px 48px;
    color: #fff;
    display: inline-block;
    margin: 0px auto 35px;
    cursor: pointer;
    background-color: #ED1C24;
    font-size: 16px;
    border-radius: 6px;
    line-height: 38px;
    height: 38px;
    font-family: "MontserratarmLight"; }
    .sign-up-container .button:hover, .sign-in-container .button:hover, .forgot-password-container .button:hover, .reset-password-container .button:hover {
      opacity: .8; }
  .sign-up-container .row, .sign-in-container .row, .forgot-password-container .row, .reset-password-container .row {
    position: relative;
    text-align: left; }
    .sign-up-container .row.bottom, .sign-in-container .row.bottom, .forgot-password-container .row.bottom, .reset-password-container .row.bottom {
      text-align: center; }
    .sign-up-container .row a.forgot, .sign-in-container .row a.forgot, .forgot-password-container .row a.forgot, .reset-password-container .row a.forgot {
      font-family: "MontserratarmLight";
      color: #000;
      position: absolute;
      font-size: 12px;
      bottom: 18px;
      right: 0px;
      text-decoration: underline;
      opacity: .8; }
      .sign-up-container .row a.forgot:hover, .sign-in-container .row a.forgot:hover, .forgot-password-container .row a.forgot:hover, .reset-password-container .row a.forgot:hover {
        opacity: 1; }
    .sign-up-container .row.country-row .invalid-feedback, .sign-in-container .row.country-row .invalid-feedback, .forgot-password-container .row.country-row .invalid-feedback, .reset-password-container .row.country-row .invalid-feedback {
      bottom: -18px; }
    .sign-up-container .row.country-row .react-select, .sign-in-container .row.country-row .react-select, .forgot-password-container .row.country-row .react-select, .reset-password-container .row.country-row .react-select {
      margin-bottom: 35px; }
    .sign-up-container .row input, .sign-in-container .row input, .forgot-password-container .row input, .reset-password-container .row input {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out; }
      .sign-up-container .row input::-webkit-input-placeholder, .sign-in-container .row input::-webkit-input-placeholder, .forgot-password-container .row input::-webkit-input-placeholder, .reset-password-container .row input::-webkit-input-placeholder {
        color: #BCBEC0; }
      .sign-up-container .row input:-moz-placeholder, .sign-in-container .row input:-moz-placeholder, .forgot-password-container .row input:-moz-placeholder, .reset-password-container .row input:-moz-placeholder {
        color: #BCBEC0; }
      .sign-up-container .row input::-moz-placeholder, .sign-in-container .row input::-moz-placeholder, .forgot-password-container .row input::-moz-placeholder, .reset-password-container .row input::-moz-placeholder {
        color: #BCBEC0; }
      .sign-up-container .row input:-ms-input-placeholder, .sign-in-container .row input:-ms-input-placeholder, .forgot-password-container .row input:-ms-input-placeholder, .reset-password-container .row input:-ms-input-placeholder {
        color: #BCBEC0; }
      .sign-up-container .row input.is-invalid, .sign-in-container .row input.is-invalid, .forgot-password-container .row input.is-invalid, .reset-password-container .row input.is-invalid {
        border: 1px solid  #D93837; }
    .sign-up-container .row select, .sign-in-container .row select, .forgot-password-container .row select, .reset-password-container .row select {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out; }
      .sign-up-container .row select.is-invalid, .sign-in-container .row select.is-invalid, .forgot-password-container .row select.is-invalid, .reset-password-container .row select.is-invalid {
        border: 1px solid  #D93837; }
    .sign-up-container .row button, .sign-in-container .row button, .forgot-password-container .row button, .reset-password-container .row button {
      border: 0px;
      border-radius: 6px;
      padding: 0px 48px;
      color: #fff;
      display: inline-block;
      margin: 0px auto 35px;
      cursor: pointer;
      background-color: #ED1C24;
      font-size: 16px;
      border-radius: 6px;
      line-height: 38px;
      height: 38px;
      font-family: "MontserratarmLight"; }
      .sign-up-container .row button:hover, .sign-in-container .row button:hover, .forgot-password-container .row button:hover, .reset-password-container .row button:hover {
        opacity: .8; }
    .sign-up-container .row .alert, .sign-in-container .row .alert, .forgot-password-container .row .alert, .reset-password-container .row .alert {
      color: #D93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight";
      margin-bottom: 15px; }
    .sign-up-container .row .invalid-feedback, .sign-in-container .row .invalid-feedback, .forgot-password-container .row .invalid-feedback, .reset-password-container .row .invalid-feedback {
      position: absolute;
      color: #D93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
    .sign-up-container .row .info, .sign-in-container .row .info, .forgot-password-container .row .info, .reset-password-container .row .info {
      font-family: "MontserratarmLight";
      color: #676767;
      font-size: 14px;
      margin-bottom: 35px; }
      .sign-up-container .row .info a, .sign-in-container .row .info a, .forgot-password-container .row .info a, .reset-password-container .row .info a {
        text-decoration: underline;
        color: #676767; }
  .sign-up-container .social-login-box .title, .sign-in-container .social-login-box .title, .forgot-password-container .social-login-box .title, .reset-password-container .social-login-box .title {
    font-size: 14px;
    font-family: "MontserratarmLight";
    margin-bottom: 15px; }
  .sign-up-container .social-login-box button, .sign-in-container .social-login-box button, .forgot-password-container .social-login-box button, .reset-password-container .social-login-box button {
    background: transparent;
    border: 0px;
    display: inline-block;
    vertical-align: top;
    margin-right: 15px; }
    .sign-up-container .social-login-box button svg, .sign-in-container .social-login-box button svg, .forgot-password-container .social-login-box button svg, .reset-password-container .social-login-box button svg {
      display: block;
      width: auto;
      height: 34px;
      cursor: pointer; }
    .sign-up-container .social-login-box button:last-child, .sign-in-container .social-login-box button:last-child, .forgot-password-container .social-login-box button:last-child, .reset-password-container .social-login-box button:last-child {
      margin-right: 0px; }

.agree-policy-box {
  padding: 50px 0px 70px; }
  .agree-policy-box .wrapper {
    max-width: 900px; }
  .agree-policy-box .title {
    font-size: 26px;
    color: #000;
    font-family: "MontserratarmLight";
    width: 100%;
    text-align: center;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
  .agree-policy-box .bottom {
    margin-top: 35px;
    text-align: center; }
    .agree-policy-box .bottom .row {
      text-align: left;
      width: 100%; }
    .agree-policy-box .bottom .left {
      max-width: 540px;
      float: left;
      position: relative;
      width: calc(100% - 40px); }
    .agree-policy-box .bottom .right {
      float: right; }
  .agree-policy-box input[type=checkbox] {
    width: auto;
    height: auto;
    display: inline-block;
    margin-bottom: 0px;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox; }
  .agree-policy-box input[type=checkbox].css-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0; }
  .agree-policy-box input[type=checkbox].css-checkbox + label.css-label {
    padding-left: 23px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 12px;
    font-family: "MontserratarmSemiBold";
    vertical-align: middle;
    cursor: pointer;
    line-height: 18px; }
  .agree-policy-box input[type=checkbox].css-checkbox:checked + label.css-label {
    background-position: 0 -18px; }
  .agree-policy-box label.css-label {
    background-image: url(../images/checkbox-button.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer; }
  .agree-policy-box h2 {
    font-size: 14px;
    font-family: "MontserratarmSemiBold";
    margin-bottom: 3px; }
  .agree-policy-box .content-box {
    height: 70%;
    overflow: auto; }
  .agree-policy-box p {
    font-size: 14px;
    line-height: 24px;
    font-family: "MontserratarmLight";
    letter-spacing: 0.02em; }
    .agree-policy-box p a {
      color: #000;
      text-decoration: underline; }
      .agree-policy-box p a:hover {
        color: #636363; }
  .agree-policy-box .wrap-box {
    margin-bottom: 25px; }
  .agree-policy-box .description {
    margin: 15px 0px 25px;
    font-size: 16px;
    line-height: 22px;
    font-family: "MontserratarmLight"; }
  .agree-policy-box a.pdf-link {
    display: inline-block; }
    .agree-policy-box a.pdf-link svg {
      max-width: 40px;
      display: block;
      height: auto;
      width: 100%; }
      .agree-policy-box a.pdf-link svg .cls-1 {
        fill: #e2e5e7; }
      .agree-policy-box a.pdf-link svg .cls-2 {
        fill: #b0b7bd; }
      .agree-policy-box a.pdf-link svg .cls-3 {
        fill: #cad1d8; }
      .agree-policy-box a.pdf-link svg .cls-4 {
        fill: #ed1c24; }
      .agree-policy-box a.pdf-link svg .cls-5 {
        fill: #fff; }
  .agree-policy-box button, .agree-policy-box a.button {
    margin-top: 80px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    width: 130px;
    line-height: 36px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid #ED1C24;
    color: #ED1C24;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    font-family: "MontserratarmLight";
    cursor: pointer; }
    .agree-policy-box button:last-child, .agree-policy-box a.button:last-child {
      margin-left: 20px; }
    .agree-policy-box button[disabled], .agree-policy-box a.button[disabled] {
      color: #d4d4d4;
      border: 1px solid #d4d4d4;
      cursor: not-allowed; }
      .agree-policy-box button[disabled]:hover, .agree-policy-box a.button[disabled]:hover {
        color: #d4d4d4;
        border: 1px solid #d4d4d4;
        background-color: transparent; }
    .agree-policy-box button:hover, .agree-policy-box a.button:hover {
      background-color: #ED1C24;
      color: #fff; }

@media screen and (max-width: 1680px) {
  .sign-up-container > .table, .sign-in-container > .table, .forgot-password-container > .table, .reset-password-container > .table {
    height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .sign-up-container, .sign-in-container, .forgot-password-container, .reset-password-container {
    max-width: 450px; }
    .sign-up-container .section-title, .sign-up-container h1, .sign-in-container .section-title, .sign-in-container h1, .forgot-password-container .section-title, .forgot-password-container h1, .reset-password-container .section-title, .reset-password-container h1 {
      font-size: 22px;
      margin-bottom: 24px; }
    .sign-up-container .section-description, .sign-in-container .section-description, .forgot-password-container .section-description, .reset-password-container .section-description {
      font-size: 13px;
      margin: 0px 0px 30px; }
    .sign-up-container .row input, .sign-in-container .row input, .forgot-password-container .row input, .reset-password-container .row input {
      height: 38px;
      line-height: 38px;
      font-size: 13px; }
    .sign-up-container .row button, .sign-in-container .row button, .forgot-password-container .row button, .reset-password-container .row button {
      font-size: 14px; }
    .sign-up-container .row a.forgot, .sign-in-container .row a.forgot, .forgot-password-container .row a.forgot, .reset-password-container .row a.forgot {
      font-size: 11px; }
    .sign-up-container .row .invalid-feedback, .sign-in-container .row .invalid-feedback, .forgot-password-container .row .invalid-feedback, .reset-password-container .row .invalid-feedback {
      font-size: 12px; }
  .agree-policy-box .title {
    font-size: 22px; }
  .agree-policy-box button, .agree-policy-box a.button {
    font-size: 14px; } }

@media screen and (max-width: 1270px) {
  .sign-up-container > .table, .sign-in-container > .table, .forgot-password-container > .table, .reset-password-container > .table {
    height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .sign-up-container > .table, .sign-in-container > .table, .forgot-password-container > .table, .reset-password-container > .table {
    height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .sign-up-container > .table, .sign-in-container > .table, .forgot-password-container > .table, .reset-password-container > .table {
    height: calc(100vh - 612px); } }

.home-container {
  min-height: calc(100vh - 419px); }
  .home-container .wrapper {
    width: 90%; }
  .home-container .pined-item-container {
    padding: 60px 0px; }
    .home-container .pined-item-container .default {
      margin-bottom: 40px;
      display: flex; }
      .home-container .pined-item-container .default .image-box {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-bottom: 33.654%;
        max-width: 700px;
        width: 60%;
        position: relative; }
        .home-container .pined-item-container .default .image-box:after {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.03);
          top: 0px;
          left: 0px; }
        .home-container .pined-item-container .default .image-box:hover:after {
          content: ''; }
      .home-container .pined-item-container .default .text-box {
        max-width: 400px;
        padding-left: 40px; }
        .home-container .pined-item-container .default .text-box .status {
          font-family: "MontserratarmSemiBold";
          font-size: 9px;
          text-transform: uppercase;
          color: #fff;
          background: #eb1c24;
          padding: 0px 5px;
          display: inline-block;
          line-height: 16px;
          letter-spacing: 0.09em;
          margin-bottom: 8px; }
          .home-container .pined-item-container .default .text-box .status a {
            color: #fff; }
        .home-container .pined-item-container .default .text-box .box {
          margin-bottom: 8px; }
          .home-container .pined-item-container .default .text-box .box .divider {
            display: inline-block;
            vertical-align: middle;
            width: 2px;
            height: 12px;
            background-color: #eb1c24;
            margin: 0px 15px; }
          .home-container .pined-item-container .default .text-box .box .view-count {
            font-size: 14px;
            font-family: "MontserratarmLight";
            display: inline-block;
            vertical-align: top;
            color: #c0c4cb;
            margin-top: 2px; }
            .home-container .pined-item-container .default .text-box .box .view-count span {
              display: inline-block;
              vertical-align: top; }
              .home-container .pined-item-container .default .text-box .box .view-count span svg {
                margin-top: 5.2px;
                margin-right: 5px;
                display: block;
                width: 15px;
                height: auto;
                fill: #c0c4cb; }
          .home-container .pined-item-container .default .text-box .box .post-date {
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            font-family: "MontserratarmLight";
            color: #c0c4cb;
            margin-right: 15px; }
            .home-container .pined-item-container .default .text-box .box .post-date time {
              display: inline-block;
              vertical-align: middle; }
            .home-container .pined-item-container .default .text-box .box .post-date svg {
              margin-right: 5px;
              display: inline-block;
              vertical-align: middle;
              width: 13px;
              height: auto; }
              .home-container .pined-item-container .default .text-box .box .post-date svg path {
                fill: #c0c4cb; }
        .home-container .pined-item-container .default .text-box .autor {
          margin: 5px 0px;
          font-size: 15px;
          font-family: "MontserratarmLight"; }
          .home-container .pined-item-container .default .text-box .autor a {
            color: #c0c4cb;
            text-decoration: underline; }
            .home-container .pined-item-container .default .text-box .autor a:hover {
              text-decoration: none; }
        .home-container .pined-item-container .default .text-box .title {
          font-size: 18px;
          color: #fff;
          font-family: "MontserratarmSemiBold";
          line-height: 25px; }
          .home-container .pined-item-container .default .text-box .title a {
            color: #fff; }
          .home-container .pined-item-container .default .text-box .title:hover {
            text-decoration: underline; }
        .home-container .pined-item-container .default .text-box .description {
          margin: 15px 0px;
          font-size: 14px;
          color: #fff;
          font-family: "MontserratarmLight";
          line-height: 22px; }
  .home-container .post-section {
    background: #fff;
    padding-bottom: 30px; }
    .home-container .post-section .commercial-block {
      margin: 28px 0px 0px;
      padding-bottom: 28px;
      border-bottom: 1px solid #dcdcdc; }
      .home-container .post-section .commercial-block img {
        max-width: 720px;
        margin: auto; }

.post-item-container .post-item {
  margin-right: 2%;
  float: left;
  width: 23.5%;
  margin-bottom: 50px; }
  .post-item-container .post-item:nth-of-type(4n) {
    margin-right: 0px; }
  .post-item-container .post-item .image-box {
    position: relative;
    padding-bottom: 56.5%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    .post-item-container .post-item .image-box img {
      width: 100%;
      display: block;
      height: auto;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto; }
    .post-item-container .post-item .image-box:after {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.05);
      top: 0px;
      left: 0px; }
    .post-item-container .post-item .image-box .sponsored-label {
      position: absolute;
      font-family: "MontserratarmSemiBold";
      font-size: 10px;
      color: #fff;
      background: #000;
      padding: 0px 5px;
      display: inline-block;
      line-height: 16px;
      letter-spacing: 0.07em; }
    .post-item-container .post-item .image-box:hover:after {
      content: ''; }
  .post-item-container .post-item .permalink {
    margin-top: 18px;
    font-family: "WeblySleekUISemibold";
    font-size: 9px;
    text-transform: uppercase;
    color: #fff;
    background: #eb1c24;
    padding: 0px 5px;
    display: inline-block;
    line-height: 16px;
    letter-spacing: .09em; }
  .post-item-container .post-item .box {
    margin-top: 12px; }
    .post-item-container .post-item .box .divider {
      display: inline-block;
      vertical-align: middle;
      width: 2px;
      height: 12px;
      background-color: #eb1c24;
      margin: 0px 15px; }
    .post-item-container .post-item .box .view-count {
      font-size: 14px;
      font-family: "MontserratarmLight";
      display: inline-block;
      vertical-align: top;
      color: #565656;
      margin-top: 2px; }
      .post-item-container .post-item .box .view-count span {
        display: inline-block;
        vertical-align: top; }
        .post-item-container .post-item .box .view-count span svg {
          margin-top: 4.2px;
          margin-right: 5px;
          display: block;
          width: 15px;
          height: auto;
          fill: #565656; }
    .post-item-container .post-item .box .post-date {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #565656;
      margin-right: 15px; }
      .post-item-container .post-item .box .post-date time {
        display: inline-block;
        vertical-align: middle; }
      .post-item-container .post-item .box .post-date svg {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: auto; }
        .post-item-container .post-item .box .post-date svg path {
          fill: #565656; }
  .post-item-container .post-item .autor {
    margin: 5px 0px;
    font-size: 14px;
    font-family: "MontserratarmLight"; }
    .post-item-container .post-item .autor a {
      color: #565656;
      text-decoration: underline; }
      .post-item-container .post-item .autor a:hover {
        text-decoration: none; }
  .post-item-container .post-item .title {
    max-height: 90px;
    min-height: 90px;
    font-size: 15px;
    font-family: "MontserratarmSemiBold";
    color: #010101;
    line-height: 22px;
    margin-top: 2px; }
    .post-item-container .post-item .title:hover {
      text-decoration: underline; }
  .post-item-container .post-item .description {
    max-height: 57px;
    min-height: 57px;
    font-size: 13px;
    font-family: "MontserratarmLight";
    color: #010101;
    line-height: 19px;
    margin-bottom: 25px; }
  .post-item-container .post-item.placeholder svg {
    display: block;
    width: 100%;
    height: auto; }
    .post-item-container .post-item.placeholder svg rect {
      fill: #efefef; }

.home-container .post-item-wrap .post-item-container {
  border-bottom: 1px solid #dcdcdc; }

.home-container .post-item-wrap:last-child .post-item-container {
  border-bottom: 0px; }

.home-container .post-item-container .section-title {
  display: inline-block;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "MontserratarmSemiBold";
  margin-bottom: 22px;
  margin-top: 45px;
  letter-spacing: 0.09em; }

.home-container .post-item-container .post-item {
  margin-right: 2%;
  float: left;
  width: 23.5%;
  margin-bottom: 26px; }
  .home-container .post-item-container .post-item:nth-child(4) {
    margin-right: 0px; }

.home-container .featured .post-item-container .post-item {
  margin-bottom: 0px; }
  .home-container .featured .post-item-container .post-item .title {
    color: #fff; }
  .home-container .featured .post-item-container .post-item .description {
    color: #fff; }
  .home-container .featured .post-item-container .post-item .autor a {
    color: #c0c4cb; }
  .home-container .featured .post-item-container .post-item .box .view-count {
    color: #c0c4cb; }
    .home-container .featured .post-item-container .post-item .box .view-count span svg path {
      fill: #c0c4cb; }
  .home-container .featured .post-item-container .post-item .box .post-date {
    color: #c0c4cb; }
    .home-container .featured .post-item-container .post-item .box .post-date svg path {
      fill: #c0c4cb; }
  .home-container .featured .post-item-container .post-item.placeholder svg rect {
    fill: #0e0e0e; }

.category-container .post-item-container .post-item-wrap .post-item:nth-child(5n - 1) {
  margin-right: 0; }

.category-container .post-item-container .post-item-wrap .post-item:last-child {
  margin-right: 0px; }

@media screen and (max-width: 1024px) {
  .post-item-container .post-item .title {
    font-size: 13px;
    line-height: 20px; }
  .home-container .pined-item-container .default .text-box .title {
    font-size: 13px;
    line-height: 20px; } }

@media screen and (max-width: 768px) {
  .home-container .post-item-container .post-item {
    width: 49%; }
    .home-container .post-item-container .post-item:nth-child(2n) {
      margin-right: 0px; }
  .post-item-container .post-item {
    width: 49%; }
    .post-item-container .post-item:nth-child(2n) {
      margin-right: 0px; }
  .category-container .post-item-container .post-item-wrap .post-item:nth-child(5n-1) {
    margin-right: 1%; }
  .category-container .post-item {
    margin: 0px 1%;
    width: 48%; }
    .category-container .post-item:nth-child(2n) {
      margin-right: 1%; } }

@media screen and (max-width: 520px) {
  .post-item-container .post-item {
    width: 100%;
    margin-right: 0;
    margin-left: 0px;
    margin-bottom: 26px; }
    .post-item-container .post-item:nth-child(3n) {
      margin-right: 0; }
    .post-item-container .post-item:nth-child(2n) {
      margin-right: 0px; }
    .post-item-container .post-item .title {
      min-height: inherit;
      max-height: inherit; }
  .home-container .post-item-container .post-item {
    width: 100%;
    margin-right: 0; }
    .home-container .post-item-container .post-item:nth-child(4) {
      margin-right: 0px; }
    .home-container .post-item-container .post-item:nth-child(3n) {
      margin-right: 0; }
    .home-container .post-item-container .post-item:nth-child(2n) {
      margin-right: 0px; }
  .home-container .pined-item-container {
    margin-bottom: 26px; }
    .home-container .pined-item-container .default {
      display: block; }
      .home-container .pined-item-container .default .image-box {
        display: block;
        padding-bottom: 56.5%;
        width: 100%; }
      .home-container .pined-item-container .default .text-box {
        max-width: inherit;
        padding: 0px; }
        .home-container .pined-item-container .default .text-box .status {
          margin-top: 18px; }
  .home-container .featured .post-item-container .post-item {
    margin-bottom: 26px; } }

.post-inner-container {
  padding: 50px 0px;
  user-select: none; }
  .post-inner-container .post-wrapper {
    position: relative;
    max-width: 1000px;
    width: 90%;
    margin: 0 auto; }
  .post-inner-container .post-container {
    max-width: 650px;
    width: 65.7%;
    z-index: 2;
    position: relative; }
    .post-inner-container .post-container .top-section .category-name {
      font-family: "MontserratarmSemiBold";
      font-size: 9px;
      text-transform: uppercase;
      color: #fff;
      background: #eb1c24;
      padding: 0px 5px;
      display: inline-block;
      line-height: 16px;
      user-select: none;
      letter-spacing: 0.09em; }
    .post-inner-container .post-container .top-section .title {
      margin: 20px 0px;
      font-size: 22px;
      line-height: 28px;
      color: #000;
      font-family: "MontserratarmSemiBold"; }
    .post-inner-container .post-container .top-section .autor {
      font-family: "MontserratarmLight";
      font-size: 16px;
      display: inline-block;
      vertical-align: middle; }
      .post-inner-container .post-container .top-section .autor a {
        color: #000; }
        .post-inner-container .post-container .top-section .autor a:hover {
          text-decoration: underline; }
    .post-inner-container .post-container .top-section .divider {
      display: inline-block;
      vertical-align: middle;
      width: 2px;
      height: 12px;
      background-color: #eb1c24;
      margin: 0px 15px; }
    .post-inner-container .post-container .top-section .date {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-family: "MontserratarmLight";
      color: #000; }
    .post-inner-container .post-container .top-section .date {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-right: 15px; }
      .post-inner-container .post-container .top-section .date time {
        display: inline-block;
        vertical-align: middle; }
      .post-inner-container .post-container .top-section .date svg {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: auto; }
        .post-inner-container .post-container .top-section .date svg path {
          fill: #000; }
    .post-inner-container .post-container .top-section .view-count {
      font-size: 16px;
      font-family: "MontserratarmLight";
      display: inline-block;
      vertical-align: middle;
      color: #000; }
      .post-inner-container .post-container .top-section .view-count span {
        display: inline-block;
        vertical-align: top; }
        .post-inner-container .post-container .top-section .view-count span svg {
          margin-top: 6.2px;
          margin-right: 5px;
          display: block;
          width: 15px;
          height: auto;
          fill: #000; }
    .post-inner-container .post-container .top-section img {
      margin: 40px 0px;
      display: block;
      width: 100%;
      height: auto; }
    .post-inner-container .post-container .top-section .empty-block {
      margin-top: 30px; }
    .post-inner-container .post-container .post-content h1, .post-inner-container .post-container .post-content h2, .post-inner-container .post-container .post-content h3, .post-inner-container .post-container .post-content h4, .post-inner-container .post-container .post-content h5, .post-inner-container .post-container .post-content h6 {
      font-size: 18px;
      font-family: "MontserratarmSemiBold";
      line-height: 28px;
      color: #000 !important; }
    .post-inner-container .post-container .post-content iframe {
      width: 100%;
      height: 366px; }
    .post-inner-container .post-container .post-content > div {
      margin-bottom: 30px; }
    .post-inner-container .post-container .post-content a {
      color: #1b6be4 !important;
      text-decoration: underline; }
    .post-inner-container .post-container .post-content span, .post-inner-container .post-container .post-content p {
      font-size: 18px;
      font-family: "MontserratarmLight";
      line-height: 28px;
      color: #000 !important; }
    .post-inner-container .post-container .post-content img {
      max-width: 100%;
      display: block;
      height: auto; }
    .post-inner-container .post-container .post-content .share-report-box {
      margin-bottom: 0px;
      box-sizing: border-box;
      background-color: #fff;
      width: 100%; }
      .post-inner-container .post-container .post-content .share-report-box .left {
        float: left; }
        .post-inner-container .post-container .post-content .share-report-box .left .text {
          display: inline-block;
          vertical-align: middle;
          font-size: 15px;
          font-family: "MontserratarmSemiBold"; }
        .post-inner-container .post-container .post-content .share-report-box .left div {
          display: inline-block !important;
          vertical-align: middle;
          margin-right: 12px;
          visibility: visible !important; }
          .post-inner-container .post-container .post-content .share-report-box .left div svg {
            display: block !important;
            visibility: visible !important; }
          .post-inner-container .post-container .post-content .share-report-box .left div:last-child {
            margin-right: 0px; }
          .post-inner-container .post-container .post-content .share-report-box .left div[role="button"] {
            cursor: pointer; }
      .post-inner-container .post-container .post-content .share-report-box .right {
        float: right; }
        .post-inner-container .post-container .post-content .share-report-box .right .report-button {
          cursor: pointer;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          text-align: center;
          display: block;
          line-height: 24px;
          height: 26px;
          color: #000;
          font-family: "MontserratarmLight"; }
          .post-inner-container .post-container .post-content .share-report-box .right .report-button span {
            font-size: 15px;
            display: inline-block;
            vertical-align: middle; }
          .post-inner-container .post-container .post-content .share-report-box .right .report-button svg {
            width: 14px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px; }
            .post-inner-container .post-container .post-content .share-report-box .right .report-button svg path {
              transition: all .3s ease-in-out;
              fill: #000; }
          .post-inner-container .post-container .post-content .share-report-box .right .report-button:hover svg path {
            fill: #ED1C24; }
  .post-inner-container .commercial-box-container {
    position: absolute;
    right: 0px;
    top: 26px;
    height: calc(100% - 26px);
    -ms-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 28%; }
    .post-inner-container .commercial-box-container .commercial-box {
      box-sizing: border-box;
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
      top: 10%; }
  .post-inner-container .related-post-container {
    border-top: 1px solid #dcdcdc;
    padding-top: 50px;
    margin-top: 50px; }
    .post-inner-container .related-post-container .post-item {
      margin-bottom: 0px; }

@media screen and (max-width: 1440px) {
  .post-inner-container .post-container .top-section .title {
    font-size: 21px;
    user-select: none; } }

@media screen and (max-width: 768px) {
  .post-inner-container .post-container .top-section .title {
    font-size: 18px;
    line-height: 26px;
    user-select: none; } }

@media screen and (max-width: 760px) {
  .post-inner-container .post-container {
    max-width: inherit;
    width: 100%;
    user-select: none; }
    .post-inner-container .post-container .post-content .share-report-box .left, .post-inner-container .post-container .post-content .share-report-box .right {
      float: none; }
    .post-inner-container .post-container .post-content .share-report-box .right .report-button {
      margin-top: 10px;
      text-align: left; }
  .post-inner-container .commercial-box-container {
    position: relative;
    top: inherit;
    right: inherit;
    height: auto;
    display: block;
    width: 100%;
    margin-top: 30px; }
    .post-inner-container .commercial-box-container img {
      margin: 0 auto;
      max-width: 280px; } }

@media screen and (max-width: 520px) {
  .post-inner-container .related-post-container .post-item {
    margin-bottom: 26px;
    user-select: none; } }

.searched-post-list {
  padding: 60px 0px;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .searched-post-list .wrapper {
    max-width: 920px;
    width: 90%; }
  .searched-post-list .section-title {
    float: left;
    margin-bottom: 25px; }
    .searched-post-list .section-title span {
      font-size: 16px;
      font-family: "MontserratarmLight"; }
      .searched-post-list .section-title span.suggested-name {
        font-family: "MontserratarmSemiBold"; }
  .searched-post-list .sort-box {
    float: right; }
    .searched-post-list .sort-box span {
      font-size: 14px;
      color: #000; }
      .searched-post-list .sort-box span.name {
        font-family: "MontserratarmLight"; }
      .searched-post-list .sort-box span.count {
        font-family: "MontserratarmSemiBold"; }
    .searched-post-list .sort-box select {
      box-sizing: border-box;
      background-color: transparent;
      background-image: url(../images/svg/drop-down-icon.svg);
      background-repeat: no-repeat;
      background-size: 7px;
      background-position: right 7px;
      padding: 0px 10px 0px 0px;
      font-size: 14px;
      font-family: "MontserratarmSemiBold";
      border: 0px;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .searched-post-list .searched-post-container .error-text {
    margin-top: 25px;
    text-align: center;
    font-size: 18px;
    font-family: "MontserratarmSemiBold"; }
  .searched-post-list .searched-post-container .post-item {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 13px;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #f3f3f3;
    position: relative; }
    .searched-post-list .searched-post-container .post-item:last-child {
      border: 0px; }
    .searched-post-list .searched-post-container .post-item:hover {
      background-color: #f3f3f3; }
    .searched-post-list .searched-post-container .post-item a.permalink {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 1; }
    .searched-post-list .searched-post-container .post-item .right {
      width: 30%;
      text-align: right; }
    .searched-post-list .searched-post-container .post-item .image-box {
      width: 30%;
      float: left;
      padding-bottom: 18%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative; }
      .searched-post-list .searched-post-container .post-item .image-box .sponsored-label {
        position: absolute;
        font-family: "MontserratarmSemiBold";
        font-size: 10px;
        color: #fff;
        background: #000;
        padding: 0px 5px;
        display: inline-block;
        line-height: 16px;
        letter-spacing: 0.07em; }
    .searched-post-list .searched-post-container .post-item .box .view-count {
      font-size: 14px;
      font-family: "MontserratarmLight";
      display: inline-block;
      vertical-align: top;
      color: #565656;
      margin-top: 2px; }
      .searched-post-list .searched-post-container .post-item .box .view-count span {
        display: inline-block;
        vertical-align: top; }
        .searched-post-list .searched-post-container .post-item .box .view-count span svg {
          margin-top: 4.2px;
          margin-right: 5px;
          display: block;
          width: 15px;
          height: auto;
          fill: #565656; }
    .searched-post-list .searched-post-container .post-item .box .date {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #565656;
      margin-right: 15px; }
      .searched-post-list .searched-post-container .post-item .box .date time {
        display: inline-block;
        vertical-align: middle; }
      .searched-post-list .searched-post-container .post-item .box .date svg {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: auto; }
        .searched-post-list .searched-post-container .post-item .box .date svg path {
          fill: #565656; }
    .searched-post-list .searched-post-container .post-item .text-box {
      margin-left: 2%;
      width: 68%;
      float: right; }
    .searched-post-list .searched-post-container .post-item .description {
      font-size: 13px;
      font-family: "MontserratarmLight";
      color: #000; }
    .searched-post-list .searched-post-container .post-item .autor {
      margin: 5px 0px;
      font-size: 14px;
      font-family: "MontserratarmLight";
      position: relative;
      z-index: 2;
      display: inline-block; }
      .searched-post-list .searched-post-container .post-item .autor a {
        color: #565656;
        text-decoration: underline; }
        .searched-post-list .searched-post-container .post-item .autor a:hover {
          text-decoration: none; }
    .searched-post-list .searched-post-container .post-item .category-name {
      font-family: "MontserratarmSemiBold";
      margin-bottom: 10px;
      font-size: 9px;
      text-transform: uppercase;
      color: #fff;
      background: #eb1c24;
      padding: 0px 5px;
      display: inline-block;
      line-height: 16px;
      letter-spacing: 0.09em;
      position: relative;
      z-index: 2;
      user-select: none; }
    .searched-post-list .searched-post-container .post-item .title {
      font-size: 15px;
      font-family: "MontserratarmSemiBold";
      color: #000;
      margin-top: 5px;
      user-select: none; }

@media screen and (max-width: 1680px) {
  .searched-post-list {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .searched-post-list .section-title {
    margin-bottom: 15px; }
    .searched-post-list .section-title span {
      font-size: 14px; }
  .searched-post-list .sort-box span.name {
    font-size: 13px; }
  .searched-post-list .sort-box select {
    font-size: 13px; }
  .searched-post-list .searched-post-container .post-item .title {
    font-size: 14px; } }

@media screen and (max-width: 1270px) {
  .searched-post-list {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .searched-post-list {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .searched-post-list {
    min-height: calc(100vh - 612px); } }

@media screen and (max-width: 620px) {
  .searched-post-list .section-title, .searched-post-list .sort-box {
    float: none; }
  .searched-post-list .section-title {
    margin-bottom: 8px; }
  .searched-post-list .sort-box {
    margin-bottom: 25px; }
  .searched-post-list .searched-post-container .post-item a {
    display: block; }
  .searched-post-list .searched-post-container .post-item .category-name {
    margin-top: 18px;
    user-select: none; }
  .searched-post-list .searched-post-container .post-item .image-box, .searched-post-list .searched-post-container .post-item .text-box {
    width: 100%;
    float: none;
    margin: 0px; }
  .searched-post-list .searched-post-container .post-item .image-box {
    padding-bottom: 56.5%; } }

.category-container {
  padding: 50px 0px 50px;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .category-container .wrapper {
    width: 90%; }
  .category-container h1 {
    text-align: center;
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    margin-bottom: 50px; }
  .category-container .post-item-container .commercial-block {
    margin: 0px 0px 50px 0px;
    padding: 24px 0px;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc; }
    .category-container .post-item-container .commercial-block img {
      max-width: 720px;
      margin: auto; }
    .category-container .post-item-container .commercial-block.placeholder {
      padding: 0px;
      border: 0px; }
  .category-container ul.pagination {
    margin-top: 0px; }

@media screen and (max-width: 1680px) {
  .category-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .category-container .section-title, .category-container h1 {
    font-size: 22px;
    margin-bottom: 24px; } }

@media screen and (max-width: 1270px) {
  .category-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .category-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .category-container {
    min-height: calc(100vh - 612px); } }

.user-page-container {
  padding: 50px 0px;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .user-page-container h1 {
    text-align: center;
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    margin-bottom: 50px; }

@media screen and (max-width: 1680px) {
  .user-page-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1270px) {
  .user-page-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .user-page-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .user-page-container {
    min-height: calc(100vh - 612px); } }

.account-edit-container {
  max-width: 600px;
  width: 90%;
  margin: 0px auto; }
  .account-edit-container > .table {
    box-sizing: border-box;
    min-height: calc(100vh - 471px);
    padding: 50px 0px; }
  .account-edit-container h1 {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 15px; }
  .account-edit-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 0px 0px 40px; }
  .account-edit-container .row {
    position: relative; }
    .account-edit-container .row.bottom {
      text-align: center; }
    .account-edit-container .row.top {
      margin-bottom: 40px; }
      .account-edit-container .row.top .email-text {
        font-size: 14px;
        font-family: "MontserratarmSemiBold";
        margin-bottom: 5px; }
      .account-edit-container .row.top .change-password-button {
        font-size: 12px;
        font-family: "MontserratarmLight"; }
        .account-edit-container .row.top .change-password-button span {
          text-decoration: underline;
          cursor: pointer; }
    .account-edit-container .row.country-row .invalid-feedback {
      bottom: -18px; }
    .account-edit-container .row.country-row .react-select {
      margin-bottom: 35px; }
    .account-edit-container .row.settings-row {
      margin-bottom: 35px; }
      .account-edit-container .row.settings-row .invalid-feedback {
        position: relative;
        bottom: inherit; }
    .account-edit-container .row input {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out; }
      .account-edit-container .row input::-webkit-input-placeholder {
        color: #BCBEC0; }
      .account-edit-container .row input:-moz-placeholder {
        color: #BCBEC0; }
      .account-edit-container .row input::-moz-placeholder {
        color: #BCBEC0; }
      .account-edit-container .row input:-ms-input-placeholder {
        color: #BCBEC0; }
      .account-edit-container .row input.is-invalid {
        border: 1px solid  #D93837; }
    .account-edit-container .row input[type=checkbox] {
      width: auto;
      height: auto;
      display: inline-block;
      margin-bottom: 0px; }
    .account-edit-container .row input[type=checkbox].css-checkbox {
      position: absolute;
      z-index: -1000;
      left: -1000px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0; }
    .account-edit-container .row input[type=checkbox].css-checkbox + label.css-label {
      padding-left: 23px;
      height: 18px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 12px;
      font-family: "MontserratarmSemiBold";
      vertical-align: middle;
      cursor: pointer;
      line-height: 18px; }
    .account-edit-container .row input[type=checkbox].css-checkbox:checked + label.css-label {
      background-position: 0 -18px; }
    .account-edit-container .row label.css-label {
      background-image: url(../images/checkbox-button.png);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .account-edit-container .row label.passive {
      padding-left: 23px;
      height: 18px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 12px;
      font-family: "MontserratarmSemiBold";
      vertical-align: middle;
      line-height: 18px; }
    .account-edit-container .row label.passive {
      background-position: 0 -18px; }
    .account-edit-container .row label.passive {
      background-image: url(../images/checkbox-button.png);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .account-edit-container .row select {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out; }
      .account-edit-container .row select.is-invalid {
        border: 1px solid  #D93837; }
    .account-edit-container .row button {
      border: 0px;
      border-radius: 6px;
      padding: 0px 48px;
      color: #fff;
      display: inline-block;
      cursor: pointer;
      background-color: #ED1C24;
      font-size: 16px;
      border-radius: 6px;
      line-height: 38px;
      height: 38px;
      font-family: "MontserratarmLight"; }
      .account-edit-container .row button:hover {
        opacity: .8; }
      .account-edit-container .row button.updated {
        color: #000;
        background-color: #ededed;
        cursor: not-allowed; }
        .account-edit-container .row button.updated:hover {
          opacity: 1; }
    .account-edit-container .row .alert {
      color: #D93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight";
      margin-bottom: 15px; }
    .account-edit-container .row .invalid-feedback {
      position: absolute;
      color: #D93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
    .account-edit-container .row .info {
      font-family: "MontserratarmLight";
      color: #676767;
      font-size: 14px; }
      .account-edit-container .row .info a {
        text-decoration: underline;
        color: #676767; }

@media screen and (max-width: 1680px) {
  .account-edit-container > .table {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .account-edit-container {
    max-width: 450px; }
    .account-edit-container .section-title, .account-edit-container h1 {
      font-size: 22px;
      margin-bottom: 24px; }
    .account-edit-container .section-description {
      font-size: 13px;
      margin: 0px 0px 30px; }
    .account-edit-container .row input {
      height: 38px;
      line-height: 38px;
      font-size: 13px; }
    .account-edit-container .row button {
      font-size: 14px; }
    .account-edit-container .row a.forgot {
      font-size: 11px; }
    .account-edit-container .row .invalid-feedback {
      font-size: 12px; } }

@media screen and (max-width: 1270px) {
  .account-edit-container > .table {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .account-edit-container > .table {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .account-edit-container > .table {
    min-height: calc(100vh - 612px); } }

.live-container .wrapper {
  width: 90%; }

.live-container .main-container {
  background-color: #000;
  min-height: calc(100vh - 471px);
  padding: 60px 0px 0px;
  box-sizing: border-box;
  position: relative; }
  .live-container .main-container .featured-video {
    padding-top: 56.5%;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px; }
    .live-container .main-container .featured-video iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .live-container .main-container .featured-video .title {
      font-size: 13px;
      line-height: 20px;
      color: #fff;
      font-family: "MontserratarmSemiBold";
      margin-top: 10px;
      display: none; }
  .live-container .main-container iframe {
    border: 0px; }
  .live-container .main-container .no-live {
    font-family: "MontserratarmSemiBold";
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 25px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    padding: 0px 20px;
    box-sizing: border-box; }
  .live-container .main-container .item {
    margin-right: 2%;
    float: left;
    width: 23.5%;
    margin-bottom: 26px; }
    .live-container .main-container .item .iframe-wrap {
      padding-top: 56.5%;
      position: relative;
      overflow: hidden; }
      .live-container .main-container .item .iframe-wrap iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
    .live-container .main-container .item .title {
      max-height: 90px;
      min-height: 90px;
      font-size: 15px;
      font-family: "MontserratarmSemiBold";
      margin-top: 10px;
      color: #fff; }
    .live-container .main-container .item:nth-child(4n) {
      margin-right: 0px; }

@media screen and (max-width: 1680px) {
  .live-container .main-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1270px) {
  .live-container .main-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .live-container .main-container {
    min-height: calc(100vh - 404px); }
    .live-container .main-container .item .title {
      font-size: 13px;
      line-height: 20px; } }

@media screen and (max-width: 768px) {
  .live-container .main-container .item {
    width: 49%;
    margin-bottom: 0px; }
    .live-container .main-container .item:nth-child(2n) {
      margin-right: 0px; } }

@media screen and (max-width: 760px) {
  .live-container .main-container {
    min-height: calc(100vh - 612px); } }

@media screen and (max-width: 520px) {
  .live-container .main-container .featured-video {
    margin-bottom: 26px; }
    .live-container .main-container .featured-video .title {
      display: block; }
  .live-container .main-container .item {
    width: 100%;
    margin-bottom: 26px;
    margin-right: 0px; }
    .live-container .main-container .item:nth-child(2n) {
      margin-right: 0px; }
    .live-container .main-container .item .title {
      min-height: inherit;
      max-height: inherit; } }

.legal-page-container {
  padding: 40px 0;
  position: relative;
  min-height: calc(100vh - 471px);
  box-sizing: border-box; }
  .legal-page-container .legal-wrapper {
    max-width: 1568px;
    width: 94%;
    margin: 0 auto;
    box-sizing: border-box; }
  .legal-page-container .wrapper {
    max-width: 900px;
    width: 90%; }
  .legal-page-container section {
    margin-bottom: 25px; }
    .legal-page-container section:last-child {
      margin-bottom: 0; }
  .legal-page-container .wrap-box {
    margin-bottom: 25px; }
  .legal-page-container h1, .legal-page-container .title {
    font-size: 26px;
    color: #000;
    margin: 0 0 30px;
    text-align: center;
    line-height: 30px;
    font-family: "MontserratarmLight"; }
  .legal-page-container .content-box {
    box-sizing: border-box; }
    .legal-page-container .content-box h2 {
      font-size: 14px;
      margin: 0;
      color: #000;
      font-family: "MontserratarmSemiBold";
      margin-bottom: 0px; }
    .legal-page-container .content-box h3 {
      font-size: 14px;
      margin: 0;
      color: #000;
      font-family: "MontserratarmSemiBold";
      margin-bottom: 0px; }
    .legal-page-container .content-box p {
      font-size: 14px;
      color: #000;
      line-height: 24px;
      font-family: "MontserratarmLight";
      letter-spacing: 0.02em; }
      .legal-page-container .content-box p a {
        color: #000;
        text-decoration: underline; }
        .legal-page-container .content-box p a:hover {
          color: #636363; }
  .legal-page-container section {
    box-sizing: border-box; }
    .legal-page-container section h2 {
      font-weight: 400;
      font-size: 18px;
      margin: 0;
      color: #000;
      letter-spacing: 0.01em;
      font-family: "MontserratarmSemiBold"; }
    .legal-page-container section h3 {
      font-weight: 400;
      font-size: 15px;
      margin: 0;
      color: #000;
      letter-spacing: 0.01em;
      font-family: "MontserratarmSemiBold"; }
    .legal-page-container section p {
      font-weight: 300;
      font-size: 14px;
      color: #000;
      margin: 10px 0;
      line-height: 24px;
      font-family: "MontserratarmLight";
      letter-spacing: 0.02em; }
      .legal-page-container section p a {
        color: #000;
        text-decoration: underline; }
        .legal-page-container section p a:hover {
          color: #636363; }
  .legal-page-container .left {
    max-width: 200px;
    position: fixed;
    top: 98px; }
    .legal-page-container .left a {
      font-family: "MontserratarmLight";
      letter-spacing: 0.02em;
      font-size: 14px;
      text-decoration: underline;
      color: #000;
      font-weight: 400;
      margin-bottom: 20px;
      display: block;
      line-height: 22px; }
      .legal-page-container .left a:last-child {
        margin-bottom: 0; }
      .legal-page-container .left a:hover {
        color: #636363; }

@media screen and (max-width: 1680px) {
  .legal-page-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .legal-page-container .wrapper {
    max-width: 800px; }
  .legal-page-container .title, .legal-page-container h1 {
    font-size: 22px;
    margin-bottom: 24px; } }

@media screen and (max-width: 1270px) {
  .legal-page-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .legal-page-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .legal-page-container {
    min-height: calc(100vh - 612px); } }

.contact-page-container {
  max-width: 900px;
  width: 90%;
  margin: 0px auto; }
  .contact-page-container > .table {
    box-sizing: border-box;
    height: calc(100vh - 471px);
    padding: 52px 0px; }
  .contact-page-container .left {
    max-width: 300px;
    box-sizing: border-box;
    padding-right: 20px;
    width: 35%;
    text-align: left;
    float: left; }
    .contact-page-container .left .item {
      margin-bottom: 10px; }
      .contact-page-container .left .item span {
        vertical-align: middle;
        font-size: 14px;
        color: #000;
        line-height: 20px;
        font-family: "MontserratarmLight"; }
        .contact-page-container .left .item span a {
          color: #000; }
  .contact-page-container .right {
    max-width: 600px;
    float: right;
    width: 65%; }
  .contact-page-container h1 {
    font-family: "MontserratarmLight";
    font-size: 26px;
    color: #000;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
  .contact-page-container .message {
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    bottom: 18px;
    font-family: "MontserratarmLight"; }
  .contact-page-container .row {
    position: relative; }
    .contact-page-container .row.bottom {
      text-align: center; }
    .contact-page-container .row a.forgot {
      font-family: "MontserratarmLight";
      color: #000;
      position: absolute;
      font-size: 12px;
      bottom: 18px;
      right: 0px;
      text-decoration: underline;
      opacity: .8; }
      .contact-page-container .row a.forgot:hover {
        opacity: 1; }
    .contact-page-container .row input {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out; }
      .contact-page-container .row input::-webkit-input-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row input:-moz-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row input::-moz-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row input:-ms-input-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row input.is-invalid {
        border: 1px solid  #D93837; }
    .contact-page-container .row textarea {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      padding: 8px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out;
      resize: none; }
      .contact-page-container .row textarea::-webkit-input-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row textarea:-moz-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row textarea::-moz-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row textarea:-ms-input-placeholder {
        color: #BCBEC0; }
      .contact-page-container .row textarea.is-invalid {
        border: 1px solid  #D93837; }
    .contact-page-container .row select {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid  #dcdcdc;
      border-radius: 6px;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 35px;
      transition: border 0.3s ease-in-out; }
      .contact-page-container .row select.is-invalid {
        border: 1px solid  #D93837; }
    .contact-page-container .row button {
      border: 0px;
      border-radius: 6px;
      padding: 0px 48px;
      color: #fff;
      display: inline-block;
      cursor: pointer;
      background-color: #ED1C24;
      font-size: 16px;
      border-radius: 6px;
      line-height: 38px;
      height: 38px;
      font-family: "MontserratarmLight"; }
      .contact-page-container .row button:hover {
        opacity: .8; }
    .contact-page-container .row .alert {
      color: #D93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
    .contact-page-container .row .invalid-feedback {
      position: absolute;
      color: #D93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
    .contact-page-container .row .info {
      font-family: "MontserratarmLight";
      color: #676767;
      font-size: 14px; }
      .contact-page-container .row .info a {
        text-decoration: underline;
        color: #676767; }

@media screen and (max-width: 1680px) {
  .contact-page-container > .table {
    height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .contact-page-container {
    max-width: 800px; }
    .contact-page-container .title, .contact-page-container h1 {
      font-size: 22px;
      margin-bottom: 24px; }
    .contact-page-container .row input {
      height: 38px;
      line-height: 38px;
      font-size: 13px; }
    .contact-page-container .row textarea {
      font-size: 13px;
      height: 150px; }
    .contact-page-container .row button {
      font-size: 14px; }
    .contact-page-container .row a.forgot {
      font-size: 11px; }
    .contact-page-container .row .invalid-feedback {
      font-size: 12px; }
    .contact-page-container .left .item {
      margin-bottom: 6px; }
      .contact-page-container .left .item span {
        font-size: 13px;
        line-height: 18px; } }

@media screen and (max-width: 1270px) {
  .contact-page-container > .table {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .contact-page-container > .table {
    height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .contact-page-container > .table {
    height: calc(100vh - 612px); }
  .contact-page-container .left, .contact-page-container .right {
    max-width: inherit;
    float: none;
    width: 100%;
    padding: 0px; }
  .contact-page-container .left {
    text-align: center;
    margin-top: 30px; } }

.about-page-container {
  max-width: 900px;
  width: 90%;
  margin: 0px auto; }
  .about-page-container > .table {
    box-sizing: border-box;
    height: calc(100vh - 471px);
    padding: 52px 0px; }
  .about-page-container h1 {
    font-family: "MontserratarmLight";
    font-size: 26px;
    color: #000;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
  .about-page-container .text-box {
    font-weight: 300;
    font-size: 14px;
    color: #000;
    margin: 10px 0;
    line-height: 24px;
    font-family: "MontserratarmLight";
    letter-spacing: 0.02em; }

@media screen and (max-width: 1680px) {
  .about-page-container > .table {
    height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .about-page-container {
    max-width: 800px; }
    .about-page-container .section-title, .about-page-container h1 {
      font-size: 22px;
      margin-bottom: 24px; } }

@media screen and (max-width: 1270px) {
  .about-page-container > .table {
    height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .about-page-container > .table {
    height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .about-page-container > .table {
    height: calc(100vh - 612px); } }

.faq-page-container {
  max-width: 900px;
  width: 96%;
  margin: 0px auto;
  min-height: calc(100vh - 471px);
  box-sizing: border-box;
  padding: 50px 0px 50px; }
  .faq-page-container h1 {
    font-family: "MontserratarmLight";
    font-size: 26px;
    color: #000;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
  .faq-page-container .accordion .accordion__item {
    margin-bottom: 25px; }
    .faq-page-container .accordion .accordion__item:last-child {
      margin-bottom: 0px; }
    .faq-page-container .accordion .accordion__item .accordion__title {
      color: #000;
      font-family: "MontserratarmSemiBold";
      cursor: pointer;
      width: 100%;
      text-align: left;
      border: none;
      position: relative;
      padding-left: 15px; }
      .faq-page-container .accordion .accordion__item .accordion__title h3 {
        font-size: 15px; }
      .faq-page-container .accordion .accordion__item .accordion__title[aria-selected='true'] .accordion__arrow:after {
        transform: rotate(90deg); }
      .faq-page-container .accordion .accordion__item .accordion__title .accordion__arrow {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -2px; }
        .faq-page-container .accordion .accordion__item .accordion__title .accordion__arrow:after {
          transform: rotate(0deg);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 3px 0 3px 6px;
          border-color: transparent transparent transparent #000000;
          display: block;
          content: '';
          transition: transform 0.25s ease, -webkit-transform 0.25s ease; }
    .faq-page-container .accordion .accordion__item .accordion__body {
      padding: 5px 15px 5px;
      display: block;
      animation: fadein 0.35s ease-in;
      font-size: 15px;
      font-family: "MontserratarmLight";
      line-height: 24px; }
      .faq-page-container .accordion .accordion__item .accordion__body.accordion__body--hidden {
        display: none;
        opacity: 0;
        animation: fadein 0.35s ease-in; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.earnings-page-container {
  max-width: 900px;
  width: 90%;
  margin: 0px auto;
  padding: 50px 0px; }
  .earnings-page-container h1 {
    font-family: "MontserratarmLight";
    font-size: 26px;
    color: #000;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
  .earnings-page-container div.pdf-link span, .earnings-page-container div.pdf-link svg {
    margin-bottom: 0px;
    display: inline-block;
    vertical-align: middle; }
  .earnings-page-container div.pdf-link a:hover span {
    color: #636363; }
  .earnings-page-container div.pdf-link span {
    color: #000;
    text-decoration: underline; }
  .earnings-page-container div.pdf-link svg {
    margin-right: 15px;
    max-width: 32px;
    height: auto;
    width: 100%; }
    .earnings-page-container div.pdf-link svg .cls-1 {
      fill: #e2e5e7; }
    .earnings-page-container div.pdf-link svg .cls-2 {
      fill: #b0b7bd; }
    .earnings-page-container div.pdf-link svg .cls-3 {
      fill: #cad1d8; }
    .earnings-page-container div.pdf-link svg .cls-4 {
      fill: #ed1c24; }
    .earnings-page-container div.pdf-link svg .cls-5 {
      fill: #fff; }
  .earnings-page-container .text-box {
    font-weight: 300;
    font-size: 14px;
    color: #000;
    line-height: 24px;
    font-family: "MontserratarmLight";
    letter-spacing: 0.02em; }
    .earnings-page-container .text-box span {
      display: block;
      margin-bottom: 50px; }
      .earnings-page-container .text-box span:last-child {
        margin-bottom: 0px; }
  .earnings-page-container svg {
    margin: 0 auto;
    margin-bottom: 48px; }
    .earnings-page-container svg.scheme-1 {
      max-width: 560px;
      width: 70%;
      display: block;
      height: auto; }
      .earnings-page-container svg.scheme-1 .cls-1 {
        fill: #f5f6f7; }
      .earnings-page-container svg.scheme-1 .cls-2 {
        fill: #fff; }
      .earnings-page-container svg.scheme-1 .cls-3 {
        fill: none;
        stroke: #ed1c24;
        stroke-width: 1.5px; }
    .earnings-page-container svg.scheme-2 {
      width: 49%;
      display: inline-block;
      vertical-align: top;
      margin-right: 2%; }
      .earnings-page-container svg.scheme-2 .cls-1 {
        fill: #f5f6f7; }
      .earnings-page-container svg.scheme-2 .cls-2 {
        fill: #e4e5e6; }
      .earnings-page-container svg.scheme-2 .cls-3 {
        fill: #fff; }
      .earnings-page-container svg.scheme-2 .cls-4 {
        fill: none;
        stroke: #ed1c24;
        stroke-width: 1.5px; }
    .earnings-page-container svg.scheme-3 {
      width: 49%;
      display: inline-block;
      vertical-align: top; }
      .earnings-page-container svg.scheme-3 .cls-1 {
        fill: #f5f6f7; }
      .earnings-page-container svg.scheme-3 .cls-2 {
        fill: #e4e5e6; }
      .earnings-page-container svg.scheme-3 .cls-3 {
        fill: #fff; }
      .earnings-page-container svg.scheme-3 .cls-4 {
        fill: none;
        stroke: #ed1c24;
        stroke-width: 1.5px; }
    .earnings-page-container svg.scheme-4 {
      max-width: 560px;
      width: 70%;
      display: block;
      height: auto; }
      .earnings-page-container svg.scheme-4 .cls-1 {
        fill: #f5f6f7; }
    .earnings-page-container svg.scheme-5 {
      max-width: 900px;
      width: 96%;
      display: block;
      height: auto; }
      .earnings-page-container svg.scheme-5 .cls-1 {
        fill: none; }
      .earnings-page-container svg.scheme-5 .cls-2, .earnings-page-container svg.scheme-5 .cls-3 {
        stroke: none; }
      .earnings-page-container svg.scheme-5 .cls-3 {
        fill: #ed1c24; }

@media screen and (max-width: 1440px) {
  .earnings-page-container {
    max-width: 800px; }
    .earnings-page-container .title, .earnings-page-container h1 {
      font-size: 22px;
      margin-bottom: 24px; } }

@media screen and (max-width: 760px) {
  .earnings-page-container svg.scheme-3, .earnings-page-container svg.scheme-2, .earnings-page-container svg.scheme-1 {
    margin: 0px auto 35px;
    display: block;
    width: 100%;
    max-width: 560px; } }

:root {
  --color-text: #fff;
  --color-bg: #000;
  --color-link: #f9d77e;
  --color-link-hover: #fff;
  --color-info: #efc453;
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --time-anim: 4s;
  --delay-anim: 2s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: transparent;
  --blend-color-5: #af4949; }

.error-page {
  padding: 0px !important; }
  .error-page header, .error-page footer {
    display: none; }
  .error-page .error-page-container {
    height: 100vh;
    width: 100%;
    position: relative; }
    .error-page .error-page-container:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.35);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 2; }
    .error-page .error-page-container:before {
      content: '';
      background: linear-gradient(90deg, rgba(20, 20, 28, 0.5) 1px, transparent 1%) center, linear-gradient(rgba(20, 20, 28, 0.5) 1px, transparent 1%) center, transparent;
      background-size: 3px 3px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 1; }
    .error-page .error-page-container .logo {
      position: absolute;
      z-index: 4;
      top: 0px;
      left: 0px;
      padding: 30px 0px 0px 40px; }
      .error-page .error-page-container .logo a {
        display: inline-block; }
        .error-page .error-page-container .logo a img {
          width: 200px;
          display: block;
          height: auto; }
    .error-page .error-page-container .table {
      position: relative;
      z-index: 3;
      display: table;
      height: 100%;
      width: 100%; }
      .error-page .error-page-container .table .cell {
        display: table-cell;
        vertical-align: middle;
        width: 100%; }
    .error-page .error-page-container .wrap {
      text-align: center;
      color: #fff;
      max-width: 520px;
      width: 90%;
      margin: 0 auto; }
    .error-page .error-page-container .description {
      font-family: "MontserratarmLight";
      font-size: 26px;
      letter-spacing: 0.05em;
      margin: 5px 0px 100px; }
    .error-page .error-page-container svg {
      display: block;
      height: auto;
      max-width: 360px;
      width: 100%;
      margin: 0 auto; }
      .error-page .error-page-container svg .cls-1 {
        fill: #fff; }
    .error-page .error-page-container a.button {
      border: 0px;
      border-radius: 6px;
      padding: 0px 48px;
      color: #fff;
      display: inline-block;
      margin: 0px auto 35px;
      cursor: pointer;
      background-color: #ED1C24;
      font-size: 16px;
      border-radius: 6px;
      line-height: 38px;
      height: 38px;
      font-family: "MontserratarmLight"; }
      .error-page .error-page-container a.button:hover {
        opacity: .8; }
  .error-page .glitch {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--glitch-width);
    height: var(--glitch-height);
    overflow: hidden; }
    .error-page .glitch .glitch__bg {
      background-size: cover;
      background: url(../images/bg.jpg) no-repeat 50% 0;
      position: absolute;
      top: calc(-1 * var(--gap-vertical));
      left: calc(-1 * var(--gap-horizontal));
      width: calc(100% + var(--gap-horizontal) * 2);
      height: calc(100% + var(--gap-vertical) * 2); }
  .error-page .glitch__img {
    position: absolute;
    top: calc(-1 * var(--gap-vertical));
    left: calc(-1 * var(--gap-horizontal));
    width: calc(100% + var(--gap-horizontal) * 2);
    height: calc(100% + var(--gap-vertical) * 2);
    background: url(../images/bg.jpg) no-repeat 50% 0;
    background-color: var(--blend-color-1);
    background-size: cover;
    transform: translate3d(0, 0, 0);
    background-blend-mode: var(--blend-mode-1); }
  .error-page .glitch__img:nth-child(n+2) {
    opacity: 0; }
  .error-page .imgloaded .glitch__img:nth-child(n+2) {
    animation-duration: var(--time-anim);
    animation-delay: var(--delay-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
  .error-page .imgloaded .glitch__img:nth-child(2) {
    background-color: var(--blend-color-2);
    background-blend-mode: var(--blend-mode-2);
    animation-name: glitch-anim-1; }
  .error-page .imgloaded .glitch__img:nth-child(3) {
    background-color: var(--blend-color-3);
    background-blend-mode: var(--blend-mode-3);
    animation-name: glitch-anim-2; }
  .error-page .imgloaded .glitch__img:nth-child(4) {
    background-color: var(--blend-color-4);
    background-blend-mode: var(--blend-mode-4);
    animation-name: glitch-anim-3; }
  .error-page .imgloaded .glitch__img:nth-child(5) {
    background-color: var(--blend-color-5);
    background-blend-mode: var(--blend-mode-5);
    animation-name: glitch-anim-flash; }

@keyframes glitch-anim-1 {
  0% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%); }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%); }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%); }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%); }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }
  21.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0); }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%); }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%); }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%); }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%); }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%); }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%); }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%); }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%); }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%); }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0); }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%); }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%); }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%); }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%); }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%); }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%); }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%); }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%); }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%); }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%); }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%); }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%); }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%); }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%); }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%); }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%); }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1); }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-text {
  0% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }
  2% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }
  4% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }
  5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }
  6% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }
  7% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }
  8% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }
  9% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }
  9.9% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1); }
  10%, 100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

@keyframes glitch-anim-flash {
  0%, 5% {
    opacity: 0.2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0); }
  5.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0); } }
  .error-page:not(.mobile-view) .description {
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.25); }
  .error-page:not(.mobile-view) svg {
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.2); }

@media screen and (max-width: 1440px) {
  .error-page .error-page-container .logo a img {
    width: 150px; }
  .error-page .error-page-container svg {
    max-width: 300px; }
  .error-page .error-page-container .description {
    font-size: 22px;
    margin: 5px 0px 70px; }
  .error-page .error-page-container a.button {
    font-size: 14px; } }

@media screen and (max-width: 760px) {
  .error-page .error-page-container .logo {
    padding: 30px 30px 0px;
    right: 0px;
    text-align: center; }
    .error-page .error-page-container .logo a img {
      width: 130px; }
  .error-page .error-page-container svg {
    max-width: 250px; }
  .error-page .error-page-container .description {
    font-size: 18px;
    margin: 5px 0px 50px; } }

.ads-sponsored-page-container {
  padding: 50px 0px; }
  .ads-sponsored-page-container .wrap-box {
    max-width: 900px;
    width: 90%;
    margin: 0px auto; }
  .ads-sponsored-page-container h1, .ads-sponsored-page-container h2 {
    font-family: "MontserratarmLight";
    font-size: 26px;
    color: #000;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px; }
  .ads-sponsored-page-container .line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 45px 0px 40px; }
  .ads-sponsored-page-container .box {
    text-align: center; }
    .ads-sponsored-page-container .box.textAlignLeft {
      text-align: left; }
  .ads-sponsored-page-container a.button {
    border: 0px;
    border-radius: 6px;
    padding: 0px 48px;
    color: #fff;
    display: inline-block;
    margin: 0px auto 35px;
    cursor: pointer;
    background-color: #ED1C24;
    font-size: 16px;
    border-radius: 6px;
    line-height: 38px;
    height: 38px;
    font-family: "MontserratarmLight"; }
    .ads-sponsored-page-container a.button:hover {
      opacity: .8; }
    .ads-sponsored-page-container a.button.no-margin {
      margin: 0px; }
  .ads-sponsored-page-container .text-box {
    font-weight: 300;
    font-size: 14px;
    color: #000;
    line-height: 24px;
    font-family: "MontserratarmLight";
    letter-spacing: 0.02em; }
    .ads-sponsored-page-container .text-box span {
      display: block;
      margin-bottom: 50px; }
  .ads-sponsored-page-container svg {
    margin: 0 auto;
    margin-bottom: 48px; }
    .ads-sponsored-page-container svg.scheme-6 {
      max-width: 560px;
      width: 70%;
      display: block;
      height: auto; }
      .ads-sponsored-page-container svg.scheme-6 .cls-1 {
        fill: #f5f6f7; }
      .ads-sponsored-page-container svg.scheme-6 .cls-2 {
        fill: #e4e5e6; }
      .ads-sponsored-page-container svg.scheme-6 .cls-3, .ads-sponsored-page-container svg.scheme-6 .cls-5 {
        fill: none; }
      .ads-sponsored-page-container svg.scheme-6 .cls-3 {
        stroke: #ed1c24; }
      .ads-sponsored-page-container svg.scheme-6 .cls-4 {
        stroke: none; }
    .ads-sponsored-page-container svg.scheme-7 {
      max-width: 560px;
      width: 70%;
      display: block;
      height: auto; }
      .ads-sponsored-page-container svg.scheme-7 .cls-1 {
        fill: #f5f6f7; }
      .ads-sponsored-page-container svg.scheme-7 .cls-2 {
        fill: #e4e5e6; }
      .ads-sponsored-page-container svg.scheme-7 .cls-3, .ads-sponsored-page-container svg.scheme-7 .cls-5 {
        fill: none; }
      .ads-sponsored-page-container svg.scheme-7 .cls-3 {
        stroke: #ed1c24; }
      .ads-sponsored-page-container svg.scheme-7 .cls-4 {
        stroke: none; }
    .ads-sponsored-page-container svg.scheme-8 {
      max-width: 560px;
      width: 70%;
      display: block;
      height: auto; }
      .ads-sponsored-page-container svg.scheme-8 .cls-1 {
        fill: #f5f6f7; }
      .ads-sponsored-page-container svg.scheme-8 .cls-2, .ads-sponsored-page-container svg.scheme-8 .cls-4, .ads-sponsored-page-container svg.scheme-8 .cls-6 {
        fill: none; }
      .ads-sponsored-page-container svg.scheme-8 .cls-2 {
        stroke: #ed1c24; }
      .ads-sponsored-page-container svg.scheme-8 .cls-3 {
        fill: #e4e5e6; }
      .ads-sponsored-page-container svg.scheme-8 .cls-4 {
        stroke: #b9b9b9; }
      .ads-sponsored-page-container svg.scheme-8 .cls-5 {
        stroke: none; }
    .ads-sponsored-page-container svg.scheme-9 {
      max-width: 560px;
      width: 70%;
      display: block;
      height: auto; }
      .ads-sponsored-page-container svg.scheme-9 .cls-1 {
        fill: #f5f6f7; }
      .ads-sponsored-page-container svg.scheme-9 .cls-2 {
        fill: #e4e5e6; }
      .ads-sponsored-page-container svg.scheme-9 .cls-3, .ads-sponsored-page-container svg.scheme-9 .cls-5 {
        fill: none; }
      .ads-sponsored-page-container svg.scheme-9 .cls-3 {
        stroke: #ed1c24; }
      .ads-sponsored-page-container svg.scheme-9 .cls-4 {
        stroke: none; }

@media screen and (max-width: 1440px) {
  .ads-sponsored-page-container .wrap-box {
    max-width: 800px; }
  .ads-sponsored-page-container .section-title, .ads-sponsored-page-container h1, .ads-sponsored-page-container h2 {
    font-size: 22px;
    margin-bottom: 24px; }
  .ads-sponsored-page-container a.button {
    font-size: 14px; } }

@media screen and (max-width: 760px) {
  .ads-sponsored-page-container svg.scheme-6, .ads-sponsored-page-container svg.scheme-6, .ads-sponsored-page-container svg.scheme-7, .ads-sponsored-page-container svg.scheme-9 {
    margin: 0px auto 35px;
    display: block;
    width: 100%;
    max-width: 560px; } }

.post-info-container {
  padding: 52px 0px;
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .post-info-container .wrapper {
    max-width: 920px; }
  .post-info-container .post-info-box {
    padding-left: 280px;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 250px; }
    .post-info-container .post-info-box.no-padding-bottom {
      padding-bottom: 0px; }
    .post-info-container .post-info-box .wrapper {
      width: 90%; }
  .post-info-container .error-text {
    font-size: 14px;
    font-family: "MontserratarmSemiBold"; }
  .post-info-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 15px; }
  .post-info-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 0px 0px 40px; }
  .post-info-container .published-post-list {
    margin-bottom: 45px;
    padding-bottom: 65px;
    border-bottom: 1px solid #dcdcdc; }
    .post-info-container .published-post-list .post-item-container .post-item {
      margin-right: 2%;
      float: left;
      width: 32%;
      margin-bottom: 50px; }
      .post-info-container .published-post-list .post-item-container .post-item:nth-child(3n) {
        margin-right: 0px; }
  .post-info-container .submited-post-list .submited-post-container {
    margin-bottom: 50px; }
    .post-info-container .submited-post-list .submited-post-container .post-item {
      width: 100%;
      box-sizing: border-box;
      padding: 13px;
      transition: all 0.3s ease-in-out;
      border-bottom: 1px solid #f3f3f3; }
      .post-info-container .submited-post-list .submited-post-container .post-item:last-child {
        border: 0px; }
      .post-info-container .submited-post-list .submited-post-container .post-item:hover {
        background-color: #f3f3f3; }
      .post-info-container .submited-post-list .submited-post-container .post-item .left {
        float: left;
        width: calc(100% - 150px); }
      .post-info-container .submited-post-list .submited-post-container .post-item .right {
        float: right;
        text-align: right;
        width: 135px;
        padding-top: 20px; }
        .post-info-container .submited-post-list .submited-post-container .post-item .right.button-block {
          padding-top: 10px; }
      .post-info-container .submited-post-list .submited-post-container .post-item .permalink {
        font-family: "MontserratarmSemiBold";
        font-size: 9px;
        text-transform: uppercase;
        color: #fff;
        background: #eb1c24;
        padding: 0px 5px;
        display: inline-block;
        line-height: 16px;
        letter-spacing: 0.09em; }
      .post-info-container .submited-post-list .submited-post-container .post-item .date {
        font-size: 13px;
        font-family: "MontserratarmLight";
        color: #000;
        margin-bottom: 2px; }
        .post-info-container .submited-post-list .submited-post-container .post-item .date time {
          font-family: "MontserratarmSemiBold"; }
      .post-info-container .submited-post-list .submited-post-container .post-item .category {
        font-size: 13px;
        font-family: "MontserratarmLight";
        color: #000;
        margin-bottom: 2px; }
        .post-info-container .submited-post-list .submited-post-container .post-item .category span:last-child {
          font-family: "MontserratarmSemiBold"; }
      .post-info-container .submited-post-list .submited-post-container .post-item .title {
        margin-top: 10px;
        font-size: 13px;
        font-family: "MontserratarmSemiBold";
        color: #000;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .post-info-container .submited-post-list .submited-post-container .post-item .resend-button {
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        width: 130px;
        line-height: 38px;
        height: 38px;
        border-radius: 6px;
        border: 1px solid #ed1c24;
        color: #ed1c24;
        font-size: 14px;
        font-family: "MontserratarmLight";
        cursor: pointer; }
        .post-info-container .submited-post-list .submited-post-container .post-item .resend-button:hover {
          background-color: #ed1c24;
          color: #fff; }
      .post-info-container .submited-post-list .submited-post-container .post-item .status:before {
        content: attr(data-status);
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .post-info-container .submited-post-list .submited-post-container .post-item .status span {
        margin-top: 2px;
        margin-left: 6px;
        width: 8px;
        height: 8px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%; }
      .post-info-container .submited-post-list .submited-post-container .post-item .status[data-statusCode="P"] span, .post-info-container .submited-post-list .submited-post-container .post-item .status[data-statusCode="F"] span {
        background-color: #ffce00; }
      .post-info-container .submited-post-list .submited-post-container .post-item .status[data-statusCode="A"] span, .post-info-container .submited-post-list .submited-post-container .post-item .status[data-statusCode="paid"] span {
        background-color: #78d000; }
      .post-info-container .submited-post-list .submited-post-container .post-item .status[data-statusCode="R"] span {
        background-color: #ed1c24; }

@media screen and (max-width: 1680px) {
  .post-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .post-info-container .section-title,
  .post-info-container h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .post-info-container .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; } }

@media screen and (max-width: 1270px) {
  .post-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .post-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 768px) {
  .post-info-container .post-info-box {
    padding: 0px; } }

@media screen and (max-width: 760px) {
  .post-info-container {
    min-height: calc(100vh - 612px); }
    .post-info-container .published-post-list .post-item-container .post-item {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 25px; }
      .post-info-container .published-post-list .post-item-container .post-item:nth-child(3n) {
        margin-right: 4%; }
      .post-info-container .published-post-list .post-item-container .post-item:nth-child(2n) {
        margin-right: 0px; }
    .post-info-container .submited-post-list .submited-post-container .post-item .left,
    .post-info-container .submited-post-list .submited-post-container .post-item .right {
      width: 100%;
      float: none; }
    .post-info-container .submited-post-list .submited-post-container .post-item .right .status {
      text-align: center; } }

@media screen and (max-width: 520px) {
  .post-info-container .published-post-list .post-item-container .post-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px; }
    .post-info-container .published-post-list .post-item-container .post-item:nth-child(3n) {
      margin-right: 0; }
    .post-info-container .published-post-list .post-item-container .post-item:nth-child(2n) {
      margin-right: 0px; } }

.earnings-info-container {
  padding: 52px 0px;
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .earnings-info-container .wrapper {
    max-width: 1170px;
    width: 90%;
    position: relative; }
  .earnings-info-container .earnings-info-box {
    padding-left: 280px;
    box-sizing: border-box;
    width: 100%;
    min-height: 502px; }
    .earnings-info-container .earnings-info-box.no-padding-bottom {
      padding-bottom: 0px; }
    .earnings-info-container .earnings-info-box .month-list {
      margin-bottom: 45px;
      padding-bottom: 65px;
      border-bottom: 1px solid #dcdcdc; }
      .earnings-info-container .earnings-info-box .month-list .earnings-info-box-container .left-section {
        padding-right: 0; }
      .earnings-info-container .earnings-info-box .month-list .active-list .active-list-item {
        margin-top: 15px; }
      .earnings-info-container .earnings-info-box .month-list .active-list .row .item {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        font-family: "MontserratarmLight";
        color: #000;
        width: 33%;
        text-align: left; }
        .earnings-info-container .earnings-info-box .month-list .active-list .row .item a {
          color: #1b6be4;
          text-decoration: underline; }
        .earnings-info-container .earnings-info-box .month-list .active-list .row .item.last {
          text-align: right; }
        .earnings-info-container .earnings-info-box .month-list .active-list .row .item span {
          font-size: 14px;
          font-family: "MontserratarmLight";
          color: #000; }
          .earnings-info-container .earnings-info-box .month-list .active-list .row .item span:first-child {
            display: none; }
          .earnings-info-container .earnings-info-box .month-list .active-list .row .item span:last-child {
            font-family: "MontserratarmSemiBold"; }
  .earnings-info-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 15px; }
  .earnings-info-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 0px 0px 40px; }
  .earnings-info-container .left-section {
    padding-right: 300px; }
  .earnings-info-container .right-section {
    position: absolute;
    right: 0;
    top: 0px;
    height: 100%;
    -ms-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 250px; }
    .earnings-info-container .right-section .section-box {
      box-sizing: border-box;
      width: 100%;
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
      top: 196px;
      text-align: center;
      padding: 20px 15px;
      border: 1px solid #f3f3f3;
      border-radius: 6px; }
      .earnings-info-container .right-section .section-box .title {
        font-family: "MontserratarmSemiBold";
        font-size: 13px;
        color: #000; }
      .earnings-info-container .right-section .section-box .price {
        font-family: "MontserratarmSemiBold";
        font-size: 32px;
        color: #000; }
      .earnings-info-container .right-section .section-box .wrap-box {
        margin-top: 15px; }
        .earnings-info-container .right-section .section-box .wrap-box .item {
          margin-bottom: 15px; }
          .earnings-info-container .right-section .section-box .wrap-box .item:last-child {
            margin-bottom: 0px; }
        .earnings-info-container .right-section .section-box .wrap-box .title, .earnings-info-container .right-section .section-box .wrap-box .price {
          font-family: "MontserratarmLight"; }
      .earnings-info-container .right-section .section-box a.button {
        border: 0px;
        border-radius: 6px;
        padding: 0px 48px;
        color: #fff;
        display: inline-block;
        cursor: pointer;
        background-color: #ED1C24;
        font-size: 16px;
        border-radius: 6px;
        line-height: 38px;
        height: 38px;
        font-family: "MontserratarmLight"; }
        .earnings-info-container .right-section .section-box a.button:hover {
          opacity: .8; }
      .earnings-info-container .right-section .section-box div.button {
        border: 0px;
        border-radius: 6px;
        padding: 0px 48px;
        color: #fff;
        display: inline-block;
        cursor: not-allowed;
        color: #d4d4d4;
        border: 1px solid #d4d4d4;
        font-size: 16px;
        border-radius: 6px;
        line-height: 38px;
        height: 38px;
        font-family: "MontserratarmLight"; }
      .earnings-info-container .right-section .section-box .row {
        margin: 35px 0px 25px; }
      .earnings-info-container .right-section .section-box .expand {
        display: inline-block;
        font-size: 13px;
        text-decoration: underline;
        color: #000;
        font-family: "MontserratarmLight";
        margin-top: 20px;
        cursor: pointer; }
        .earnings-info-container .right-section .section-box .expand:hover {
          color: #636363; }
      .earnings-info-container .right-section .section-box a.link {
        font-size: 13px;
        text-decoration: underline;
        color: #000;
        font-family: "MontserratarmLight"; }
        .earnings-info-container .right-section .section-box a.link:hover {
          color: #636363; }
  .earnings-info-container .earnings-info-box-container {
    position: relative; }
    .earnings-info-container .earnings-info-box-container .top {
      margin-bottom: 60px; }
      .earnings-info-container .earnings-info-box-container .top .left {
        float: left; }
        .earnings-info-container .earnings-info-box-container .top .left > div {
          display: inline-block;
          vertical-align: middle; }
          .earnings-info-container .earnings-info-box-container .top .left > div.start-date {
            margin-right: 30px; }
          .earnings-info-container .earnings-info-box-container .top .left > div.reset {
            font-size: 12px;
            font-family: "MontserratarmSemiBold";
            color: #000;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 10px; }
          .earnings-info-container .earnings-info-box-container .top .left > div span {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            font-family: "MontserratarmLight";
            color: #000; }
          .earnings-info-container .earnings-info-box-container .top .left > div > div {
            display: inline-block;
            vertical-align: middle; }
            .earnings-info-container .earnings-info-box-container .top .left > div > div input {
              margin-left: 10px;
              border-radius: 6px;
              border: 1px solid #C7C7C7;
              width: 160px;
              height: 28px;
              font-family: "MontserratarmLight";
              line-height: 28px;
              box-sizing: border-box;
              padding: 0px 10px; }
            .earnings-info-container .earnings-info-box-container .top .left > div > div .calendar-container {
              font-family: "MontserratarmLight";
              width: 280px; }
              .earnings-info-container .earnings-info-box-container .top .left > div > div .calendar-container .col_mp {
                font-size: 14px; }
                .earnings-info-container .earnings-info-box-container .top .left > div > div .calendar-container .col_mp.selected_cell {
                  background-color: #ED1C24;
                  font-style: normal; }
                .earnings-info-container .earnings-info-box-container .top .left > div > div .calendar-container .col_mp:hover {
                  background-color: #ED1C24; }
      .earnings-info-container .earnings-info-box-container .top .right {
        float: right; }
        .earnings-info-container .earnings-info-box-container .top .right span {
          font-size: 14px;
          color: #000; }
          .earnings-info-container .earnings-info-box-container .top .right span.name {
            font-family: "MontserratarmLight"; }
          .earnings-info-container .earnings-info-box-container .top .right span.count {
            font-family: "MontserratarmSemiBold"; }
        .earnings-info-container .earnings-info-box-container .top .right select {
          box-sizing: border-box;
          background-image: url(../images/svg/drop-down-icon.svg);
          background-repeat: no-repeat;
          background-size: 7px;
          background-position: right 7px;
          padding: 0px 10px 0px 0px;
          font-size: 14px;
          font-family: "MontserratarmSemiBold";
          border: 0px;
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
    .earnings-info-container .earnings-info-box-container .earnings-list-box .error-text {
      text-align: center;
      font-family: "MontserratarmSemiBold";
      font-size: 18px; }
    .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item {
      box-sizing: border-box;
      padding: 13px;
      transition: all .3s ease-in-out;
      border-bottom: 1px solid #f3f3f3; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .left {
        width: calc(100% - 150px);
        float: left;
        max-width: 550px; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .right {
        float: right;
        width: 135px;
        padding-top: 32px;
        text-align: right; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .date {
        font-size: 13px;
        color: #000;
        font-family: "MontserratarmLight"; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .title {
        margin-top: 5px;
        font-size: 13px;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item a.link {
        font-size: 13px;
        text-decoration: underline;
        color: #000;
        font-family: "MontserratarmLight"; }
        .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item a.link:hover {
          color: #636363; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .bottom {
        margin-top: 10px; }
        .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .bottom .view-count {
          font-size: 14px;
          font-family: "MontserratarmLight";
          display: inline-block;
          vertical-align: top;
          color: #000;
          margin-right: 20px; }
          .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .bottom .view-count span {
            display: inline-block;
            vertical-align: top; }
            .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .bottom .view-count span svg {
              margin-top: 4.2px;
              margin-right: 5px;
              display: block;
              width: 15px;
              height: auto;
              fill: #565656; }
        .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .bottom .price {
          font-size: 14px;
          font-family: "MontserratarmLight";
          display: inline-block;
          vertical-align: top;
          color: #000; }
      .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item:hover {
        background-color: #f3f3f3; }

@media screen and (max-width: 1680px) {
  .earnings-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .earnings-info-container h1, .earnings-info-container .section-title {
    font-size: 22px; }
  .earnings-info-container .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; }
  .earnings-info-container .earnings-info-box {
    padding-left: 250px; }
  .earnings-info-container .left-section {
    padding-right: 260px; }
  .earnings-info-container .right-section {
    width: 220px; }
    .earnings-info-container .right-section .section-box .price {
      font-size: 28px; }
    .earnings-info-container .right-section .section-box .title {
      font-size: 12px; }
    .earnings-info-container .right-section .section-box .wrap-box {
      margin-top: 10px; }
      .earnings-info-container .right-section .section-box .wrap-box .item {
        margin-bottom: 10px; }
    .earnings-info-container .right-section .section-box .button {
      font-size: 13px;
      height: 36px;
      line-height: 36px;
      padding: 0px 38px; }
    .earnings-info-container .right-section .section-box .extend {
      font-size: 12px; }
    .earnings-info-container .right-section .section-box a.link {
      font-size: 12px; }
  .earnings-info-container .earnings-info-box-container .top .left > div span {
    font-size: 13px; }
  .earnings-info-container .earnings-info-box-container .top .left > div.start-date {
    margin-right: 20px; }
  .earnings-info-container .earnings-info-box-container .top .left > div.reset {
    font-size: 11px; }
  .earnings-info-container .earnings-info-box-container .top .left > div > div input {
    font-size: 13px; }
  .earnings-info-container .earnings-info-box-container .top .left > div > div .calendar-container {
    width: 260px; }
    .earnings-info-container .earnings-info-box-container .top .left > div > div .calendar-container .col_mp {
      font-size: 13px; }
  .earnings-info-container .earnings-info-box-container .top .right span {
    font-size: 13px; }
  .earnings-info-container .earnings-info-box-container .top .right select {
    font-size: 13px; }
  .earnings-info-container .earnings-info-box-container .earnings-list-box .error-text {
    font-size: 15px; } }

@media screen and (max-width: 1280px) {
  .earnings-info-container .earnings-info-box-container .top {
    margin-bottom: 40px; }
    .earnings-info-container .earnings-info-box-container .top .left > div span {
      display: block;
      margin-bottom: 2px; }
    .earnings-info-container .earnings-info-box-container .top .left > div.start-date {
      margin-right: 10px; }
    .earnings-info-container .earnings-info-box-container .top .left > div.reset {
      margin-left: 0px;
      margin-top: 5px;
      display: block; }
    .earnings-info-container .earnings-info-box-container .top .left > div > div input {
      margin-left: 0px; }
    .earnings-info-container .earnings-info-box-container .top .right {
      padding-top: 20px; } }

@media screen and (max-width: 1270px) {
  .earnings-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .earnings-info-container {
    min-height: calc(100vh - 404px); }
    .earnings-info-container .side-bar-continer {
      display: none; }
    .earnings-info-container .earnings-info-box {
      padding: 0px; } }

@media screen and (max-width: 768px) {
  .earnings-info-container .earnings-info-box .month-list {
    margin-bottom: 25px;
    padding-bottom: 45px; }
  .earnings-info-container .earnings-info-box-container .top .left {
    float: none; }
    .earnings-info-container .earnings-info-box-container .top .left > div.reset {
      margin-top: 14px;
      margin-left: 10px;
      display: inline-block; }
  .earnings-info-container .earnings-info-box-container .top .right {
    float: none;
    padding-top: 20px; } }

@media screen and (max-width: 760px) {
  .earnings-info-container {
    min-height: calc(100vh - 612px); }
    .earnings-info-container .left-section {
      padding: 0px; }
    .earnings-info-container .right-section {
      margin: 0 auto;
      position: relative;
      height: auto;
      top: inherit;
      margin-bottom: 15px; }
    .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .left, .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .right {
      float: none;
      width: 100%;
      max-width: inherit; }
    .earnings-info-container .earnings-info-box-container .earnings-list-box .earnings-item .right {
      padding-top: 15px;
      text-align: center; } }

@media screen and (max-width: 520px) {
  .earnings-info-container .earnings-info-box .month-list .active-list .active-list-header {
    display: none; }
  .earnings-info-container .earnings-info-box .month-list .active-list .active-list-item:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc; }
  .earnings-info-container .earnings-info-box .month-list .active-list .row .item {
    width: 100%;
    display: block;
    margin-bottom: 5px; }
    .earnings-info-container .earnings-info-box .month-list .active-list .row .item:last-child {
      margin-bottom: 0px; }
    .earnings-info-container .earnings-info-box .month-list .active-list .row .item span:first-child {
      display: inline-block; }
    .earnings-info-container .earnings-info-box .month-list .active-list .row .item.last {
      text-align: left; } }

.earnings-history-info-container {
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px);
  padding: 50px 0px; }
  .earnings-history-info-container .wrapper {
    max-width: 920px;
    width: 90% ""; }
  .earnings-history-info-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 35px; }
  .earnings-history-info-container .info-box {
    display: flex;
    text-align: center; }
    .earnings-history-info-container .info-box .item {
      width: 33.33%;
      color: #000;
      font-family: "MontserratarmLight"; }
      .earnings-history-info-container .info-box .item .title {
        font-size: 13px; }
      .earnings-history-info-container .info-box .item .value {
        font-size: 32px; }
      .earnings-history-info-container .info-box .item:last-child {
        font-family: "MontserratarmSemiBold"; }
  .earnings-history-info-container .button-row {
    text-align: center;
    margin: 60px 0px 25px; }
    .earnings-history-info-container .button-row a {
      font-family: "MontserratarmLight";
      color: #000;
      display: inline-block;
      font-size: 14px;
      text-decoration: underline; }
      .earnings-history-info-container .button-row a:hover {
        color: #636363; }
  .earnings-history-info-container .history-list-box {
    width: 100%;
    max-width: 780px;
    margin: auto; }
    .earnings-history-info-container .history-list-box .pagination {
      margin-top: 25px; }
    .earnings-history-info-container .history-list-box .history-item {
      width: 100%;
      box-sizing: border-box;
      padding: 13px;
      transition: all .3s ease-in-out;
      border-bottom: 1px solid #f3f3f3; }
      .earnings-history-info-container .history-list-box .history-item:last-child {
        border: 0px; }
      .earnings-history-info-container .history-list-box .history-item:hover {
        background-color: #f3f3f3; }
      .earnings-history-info-container .history-list-box .history-item .left {
        float: left;
        width: calc(100% - 150px); }
      .earnings-history-info-container .history-list-box .history-item .right {
        float: right;
        text-align: right;
        width: 135px;
        padding-top: 20px; }
      .earnings-history-info-container .history-list-box .history-item .permalink {
        font-family: "MontserratarmSemiBold";
        font-size: 9px;
        text-transform: uppercase;
        color: #fff;
        background: #eb1c24;
        padding: 0px 5px;
        display: inline-block;
        line-height: 16px;
        letter-spacing: 0.09em; }
      .earnings-history-info-container .history-list-box .history-item .date {
        font-size: 13px;
        font-family: "MontserratarmLight";
        color: #000;
        margin-bottom: 2px; }
        .earnings-history-info-container .history-list-box .history-item .date time {
          font-family: "MontserratarmSemiBold"; }
      .earnings-history-info-container .history-list-box .history-item .price {
        font-size: 22px;
        font-family: "MontserratarmLight";
        color: #000;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .earnings-history-info-container .history-list-box .history-item .status:before {
        content: attr(data-status);
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .earnings-history-info-container .history-list-box .history-item .status span {
        margin-top: 2px;
        margin-left: 6px;
        width: 8px;
        height: 8px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%; }
      .earnings-history-info-container .history-list-box .history-item .status[data-statusCode="P"] span, .earnings-history-info-container .history-list-box .history-item .status[data-statusCode="F"] span {
        background-color: #FFCE00; }
      .earnings-history-info-container .history-list-box .history-item .status[data-statusCode="A"] span {
        background-color: #78D000; }
      .earnings-history-info-container .history-list-box .history-item .status[data-statusCode="R"] span, .earnings-history-info-container .history-list-box .history-item .status[data-statusCode="C"] span {
        background-color: #ED1C24; }

@media screen and (max-width: 1680px) {
  .earnings-history-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .earnings-history-info-container .section-title, .earnings-history-info-container h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .earnings-history-info-container .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; }
  .earnings-history-info-container .button-row {
    margin: 40px 0px 15px; }
  .earnings-history-info-container .info-box .item .title {
    font-size: 12px; }
  .earnings-history-info-container .info-box .item .value {
    font-size: 24px; }
  .earnings-history-info-container .history-list-box .history-item .date {
    font-size: 11px; }
  .earnings-history-info-container .history-list-box .history-item .price {
    font-size: 20px; } }

@media screen and (max-width: 1270px) {
  .earnings-history-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .earnings-history-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .earnings-history-info-container {
    min-height: calc(100vh - 612px); }
    .earnings-history-info-container .info-box {
      display: block; }
      .earnings-history-info-container .info-box .item {
        margin-bottom: 10px;
        width: 100%; }
        .earnings-history-info-container .info-box .item:last-child {
          margin-bottom: 0px; } }

.post-add-container {
  position: relative; }
  .post-add-container .wrapper {
    max-width: 920px;
    width: 90%; }
  .post-add-container > .table {
    padding: 52px 0px;
    box-sizing: border-box;
    min-height: calc(100vh - 471px); }
  .post-add-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center; }
  .post-add-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 50px 0px 40px; }
  .post-add-container .form .row {
    position: relative; }
    .post-add-container .form .row input {
      width: 100%;
      line-height: 42px;
      height: 42px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 40px;
      transition: border 0.3s ease-in-out; }
      .post-add-container .form .row input::-webkit-input-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row input:-moz-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row input::-moz-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row input:-ms-input-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row input.is-invalid {
        border: 1px solid #d93837; }
      .post-add-container .form .row input[type="checkbox"] {
        width: auto;
        display: inline-block;
        vertical-align: middle;
        height: auto;
        margin-bottom: 0px;
        -webkit-appearance: checkbox;
        -moz-appearance: checkbox;
        appearance: checkbox; }
    .post-add-container .form .row input[type="checkbox"].css-checkbox {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      width: 100%;
      z-index: 9;
      height: 100%; }
    .post-add-container .form .row input[type="checkbox"].css-checkbox + label.css-label {
      padding-left: 23px;
      height: 18px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 12px;
      font-family: "MontserratarmSemiBold";
      vertical-align: middle;
      cursor: pointer;
      line-height: 18px; }
    .post-add-container .form .row input[type="checkbox"].css-checkbox:checked + label.css-label {
      background-position: 0 -18px; }
    .post-add-container .form .row label.css-label {
      background-image: url(../images/checkbox-button.png);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .post-add-container .form .row select {
      width: 100%;
      line-height: 50px;
      height: 50px;
      box-sizing: border-box;
      padding: 0px 18px;
      border: 1px solid #dcdcdc;
      border-radius: 6px;
      font-size: 18px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 40px;
      transition: border 0.3s ease-in-out; }
      .post-add-container .form .row select.is-invalid {
        border: 1px solid #d93837; }
    .post-add-container .form .row textarea {
      resize: none;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 18px;
      border: 1px solid #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      height: 200px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 40px;
      transition: border 0.3s ease-in-out; }
      .post-add-container .form .row textarea::-webkit-input-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row textarea:-moz-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row textarea::-moz-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row textarea:-ms-input-placeholder {
        color: #bcbec0; }
      .post-add-container .form .row textarea.is-invalid {
        border: 1px solid #d93837; }
    .post-add-container .form .row .invalid-feedback {
      position: absolute;
      color: #d93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
    .post-add-container .form .row.bottom {
      text-align: center; }
    .post-add-container .form .row.country-row .react-select {
      margin-bottom: 35px; }
    .post-add-container .form .row.country-row .invalid-feedback {
      bottom: -18px; }
  .post-add-container .form .file-attache {
    margin-bottom: 70px; }
    .post-add-container .form .file-attache form {
      position: relative;
      cursor: pointer; }
      .post-add-container .form .file-attache form input {
        z-index: 2;
        position: absolute;
        width: 100%;
        cursor: pointer;
        opacity: 0;
        height: 100%; }
    .post-add-container .form .file-attache span {
      margin-bottom: 15px;
      display: inline-block; }
    .post-add-container .form .file-attache > div {
      margin-bottom: 15px; }
    .post-add-container .form .file-attache .attach-button div {
      margin-right: 0; }
    .post-add-container .form .file-attache .attach-button p {
      font-family: "MontserratarmLight";
      font-size: 12px; }
      .post-add-container .form .file-attache .attach-button p.is-invalid {
        color: #d93837; }
    .post-add-container .form .file-attache div {
      display: inline-block;
      vertical-align: top;
      margin-right: 38px; }
      .post-add-container .form .file-attache div:last-child {
        margin-right: 0px; }
      .post-add-container .form .file-attache div.file-list {
        display: block;
        margin-left: 0px; }
      .post-add-container .form .file-attache div > div {
        display: inline-block;
        vertical-align: top;
        margin-right: 38px;
        cursor: pointer; }
        .post-add-container .form .file-attache div > div:last-child {
          margin-right: 0px; }
        .post-add-container .form .file-attache div > div img {
          width: 36px;
          height: 36px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 12px; }
        .post-add-container .form .file-attache div > div span,
        .post-add-container .form .file-attache div > div label {
          cursor: pointer;
          font-family: "MontserratarmLight";
          font-size: 16px;
          color: #000;
          margin-bottom: 0px;
          display: inline-block;
          position: relative;
          z-index: 1; }
  .post-add-container .form .text {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 10px 0px 25px; }
  .post-add-container .form .file-list {
    margin-bottom: 25px; }
    .post-add-container .form .file-list .item {
      margin-bottom: 5px; }
      .post-add-container .form .file-list .item span.name {
        font-family: "MontserratarmSemiBold";
        font-size: 14px;
        color: #1b6be4; }
      .post-add-container .form .file-list .item span.size {
        margin-left: 10px;
        font-family: "MontserratarmSemiBold";
        font-size: 14px; }
      .post-add-container .form .file-list .item span.remove {
        cursor: pointer;
        text-decoration: underline;
        margin-left: 10px;
        font-family: "MontserratarmLight";
        font-size: 14px; }
  .post-add-container .form a.cancel {
    border: 0px;
    border-radius: 6px;
    padding: 0px 48px;
    color: #fff;
    display: inline-block;
    margin: 0px auto 35px;
    margin-right: 15px;
    cursor: pointer;
    background-color: #ed1c24;
    font-size: 16px;
    border-radius: 6px;
    line-height: 38px;
    height: 38px;
    font-family: "MontserratarmLight"; }
    .post-add-container .form a.cancel:hover {
      opacity: 0.8; }
  .post-add-container .form button.submit {
    border: 0px;
    border-radius: 6px;
    padding: 0px 48px;
    color: #fff;
    display: inline-block;
    margin: 0px auto 35px;
    cursor: pointer;
    background-color: #ed1c24;
    font-size: 16px;
    border-radius: 6px;
    line-height: 38px;
    height: 38px;
    font-family: "MontserratarmLight"; }
    .post-add-container .form button.submit:hover {
      opacity: 0.8; }

@media screen and (max-width: 1440px) {
  .post-add-container > .table {
    min-height: calc(100vh - 465px); }
  .post-add-container .section-title,
  .post-add-container h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .post-add-container .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; }
  .post-add-container .form .text {
    font-size: 13px; }
  .post-add-container .form .row input {
    height: 38px;
    line-height: 38px;
    font-size: 13px;
    margin-bottom: 35px; }
  .post-add-container .form .row textarea {
    font-size: 13px;
    height: 150px;
    margin-bottom: 35px; }
  .post-add-container .form .row button,
  .post-add-container .form .row a.cancel {
    font-size: 14px;
    margin-bottom: 0px; }
  .post-add-container .form .row .invalid-feedback {
    font-size: 12px; }
  .post-add-container .form .file-attache {
    margin-bottom: 45px; }
    .post-add-container .form .file-attache .attach-button p {
      font-size: 10px; }
    .post-add-container .form .file-attache div {
      margin-right: 25px; }
      .post-add-container .form .file-attache div > div img {
        width: 28px;
        height: 28px;
        margin-right: 10px; }
      .post-add-container .form .file-attache div > div span,
      .post-add-container .form .file-attache div > div label {
        font-size: 14px; } }

@media screen and (max-width: 1270px) {
  .post-add-container > .table {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 520px) {
  .post-add-container .form .row.bottom a.cancel,
  .post-add-container .form .row.bottom button {
    height: 36px;
    line-height: 36px;
    padding: 0px;
    width: 120px; } }

.advertisement-add-info-container {
  box-sizing: border-box; }
  .advertisement-add-info-container .wrapper {
    padding: 50px 0px;
    max-width: 875px;
    width: 90%;
    box-sizing: border-box;
    min-height: calc(100vh - 471px); }
  .advertisement-add-info-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center; }
  .advertisement-add-info-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 50px 0px 40px; }
  .advertisement-add-info-container .advertisement-info-box .item {
    text-align: center;
    cursor: pointer;
    float: left;
    max-width: 272px;
    width: 31.09%;
    margin-right: 3.36%;
    opacity: 0.3; }
    .advertisement-add-info-container .advertisement-info-box .item:nth-child(3n) {
      margin-right: 0px; }
    .advertisement-add-info-container .advertisement-info-box .item svg {
      display: block;
      max-width: 270px;
      width: 100%;
      height: auto;
      border-radius: 6px;
      border: 1px solid #707070;
      margin-bottom: 25px; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner-inner .cls-1,
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner-inner .cls-3 {
        fill: none; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner-inner .cls-2 {
        fill: #e4e5e6; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner-inner .cls-3 {
        stroke: #ed1c24; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner-inner .cls-4 {
        stroke: none; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner .cls-1,
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner .cls-3 {
        fill: none; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner .cls-2 {
        fill: #eceff2; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner .cls-3 {
        stroke: #ed1c24; }
      .advertisement-add-info-container .advertisement-info-box .item svg.ads-baner .cls-4 {
        stroke: none; }
    .advertisement-add-info-container .advertisement-info-box .item img {
      display: block;
      max-width: 270px;
      width: 100%;
      height: auto;
      border-radius: 6px;
      border: 1px solid #707070;
      margin-bottom: 25px; }
    .advertisement-add-info-container .advertisement-info-box .item .title {
      font-size: 16px;
      font-family: "MontserratarmSemiBold";
      color: #000; }
    .advertisement-add-info-container .advertisement-info-box .item .price {
      font-size: 14px;
      font-family: "MontserratarmSemiBold";
      color: #ed1c24; }
    .advertisement-add-info-container .advertisement-info-box .item .size {
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000; }
    .advertisement-add-info-container .advertisement-info-box .item.active {
      opacity: 1; }
      .advertisement-add-info-container .advertisement-info-box .item.active svg {
        border: 1px solid #ed1c24; }
    .advertisement-add-info-container .advertisement-info-box .item:hover {
      opacity: 1; }
  .advertisement-add-info-container .category-row {
    margin-top: 45px; }
    .advertisement-add-info-container .category-row .Dropdown-root {
      margin: 0px; }
  .advertisement-add-info-container .languge-box {
    margin-top: 45px; }
    .advertisement-add-info-container .languge-box .section-description {
      margin-top: 70px;
      font-family: "MontserratarmSemiBold"; }
    .advertisement-add-info-container .languge-box .category-row {
      margin-top: 0px; }
  .advertisement-add-info-container .category-box .section-description {
    font-family: "MontserratarmSemiBold"; }
  .advertisement-add-info-container .category-box .category-row {
    margin-top: 0px; }
  .advertisement-add-info-container .date-picker .section-title {
    font-size: 30px; }
  .advertisement-add-info-container .date-picker .section-description {
    font-family: "MontserratarmSemiBold";
    margin-top: 40px; }
  .advertisement-add-info-container .date-picker .text-info {
    text-align: center; }
    .advertisement-add-info-container .date-picker .text-info .date-info {
      margin: 50px 0px 15px;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000; }
      .advertisement-add-info-container .date-picker .text-info .date-info span {
        font-family: "MontserratarmSemiBold"; }
    .advertisement-add-info-container .date-picker .text-info .price {
      font-size: 18px;
      font-family: "MontserratarmLight";
      color: #000; }
      .advertisement-add-info-container .date-picker .text-info .price span {
        font-family: "MontserratarmSemiBold"; }
  .advertisement-add-info-container .wrap-box {
    position: relative;
    padding-bottom: 1px; }
    .advertisement-add-info-container .wrap-box > div:first-child {
      margin-bottom: 40px; }
    .advertisement-add-info-container .wrap-box .invalid-feedback {
      position: absolute;
      color: #d93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
  .advertisement-add-info-container .DayPicker {
    font-family: "MontserratarmLight";
    margin: 40px auto 0px; }
    .advertisement-add-info-container .DayPicker .CalendarDay__selected,
    .advertisement-add-info-container .DayPicker .CalendarDay__selected:active,
    .advertisement-add-info-container .DayPicker .CalendarDay__selected:hover {
      background: #ed1c24;
      border: 1px double #ed1c24; }
    .advertisement-add-info-container .DayPicker .CalendarDay__highlighted_calendar {
      background: #ed1c24;
      border: 1px double #ed1c24; }
    .advertisement-add-info-container .DayPicker .DayPickerNavigation_button__verticalDefault {
      box-sizing: border-box; }
    .advertisement-add-info-container .DayPicker .DayPickerNavigation__verticalDefault {
      height: 36px; }
    .advertisement-add-info-container .DayPicker .DayPickerNavigation_svg__vertical {
      width: 20px;
      height: 20px; }
    .advertisement-add-info-container .DayPicker .CalendarDay__selected_span {
      background: #ed1c24;
      border: 1px double #ed1c24; }
    .advertisement-add-info-container .DayPicker .CalendarDay__blocked_calendar,
    .advertisement-add-info-container .DayPicker .CalendarDay__blocked_calendar:active,
    .advertisement-add-info-container .DayPicker .CalendarDay__blocked_calendar:hover {
      background: #cacccd;
      border: 1px solid #cacccd;
      color: #82888a; }
    .advertisement-add-info-container .DayPicker .CalendarDay__hovered_span,
    .advertisement-add-info-container .DayPicker .CalendarDay__hovered_span:hover {
      background: #e6464d;
      border: 1px double #e6464d; }
  .advertisement-add-info-container .form .section-description {
    font-family: "MontserratarmSemiBold";
    margin-top: 10px; }
  .advertisement-add-info-container .form .row {
    position: relative; }
    .advertisement-add-info-container .form .row.bottom {
      margin-top: 50px;
      text-align: center; }
    .advertisement-add-info-container .form .row textarea {
      resize: none;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 18px;
      border: 1px solid #dcdcdc;
      border-radius: 6px;
      font-size: 15px;
      height: 200px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 40px;
      transition: border 0.3s ease-in-out; }
      .advertisement-add-info-container .form .row textarea::-webkit-input-placeholder {
        color: #bcbec0; }
      .advertisement-add-info-container .form .row textarea:-moz-placeholder {
        color: #bcbec0; }
      .advertisement-add-info-container .form .row textarea::-moz-placeholder {
        color: #bcbec0; }
      .advertisement-add-info-container .form .row textarea:-ms-input-placeholder {
        color: #bcbec0; }
      .advertisement-add-info-container .form .row textarea.is-invalid {
        border: 1px solid #d93837; }
    .advertisement-add-info-container .form .row .invalid-feedback {
      position: absolute;
      color: #d93837;
      font-size: 14px;
      bottom: 18px;
      font-family: "MontserratarmLight"; }
  .advertisement-add-info-container .form .custom-attach-file {
    position: relative; }
    .advertisement-add-info-container .form .custom-attach-file .invalid-error {
      color: #d93837;
      font-size: 14px;
      font-family: "MontserratarmLight"; }
    .advertisement-add-info-container .form .custom-attach-file label {
      border: 1px solid #ed1c24;
      border-radius: 6px;
      padding: 0px 15px;
      color: #ed1c24;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      background-color: transparent;
      font-size: 12px;
      border-radius: 6px;
      line-height: 22px;
      height: 24px;
      font-family: "MontserratarmLight"; }
      .advertisement-add-info-container .form .custom-attach-file label:hover {
        background-color: #ed1c24;
        color: #fff; }
    .advertisement-add-info-container .form .custom-attach-file .file-name {
      margin-left: 15px;
      display: inline-block;
      vertical-align: top;
      font-family: "MontserratarmSemiBold";
      font-size: 13px;
      margin-top: 4px; }
    .advertisement-add-info-container .form .custom-attach-file input[type="file"] {
      display: none; }
  .advertisement-add-info-container .form a.cancel {
    border: 0px;
    border-radius: 6px;
    padding: 0px 48px;
    color: #fff;
    display: inline-block;
    margin: 0px auto 35px;
    margin-right: 15px;
    cursor: pointer;
    background-color: #ed1c24;
    font-size: 16px;
    border-radius: 6px;
    line-height: 38px;
    height: 38px;
    font-family: "MontserratarmLight"; }
    .advertisement-add-info-container .form a.cancel:hover {
      opacity: 0.8; }
  .advertisement-add-info-container .form button.submit {
    border: 0px;
    border-radius: 6px;
    padding: 0px 48px;
    color: #fff;
    display: inline-block;
    margin: 0px auto 35px;
    cursor: pointer;
    background-color: #ed1c24;
    font-size: 16px;
    border-radius: 6px;
    line-height: 38px;
    height: 38px;
    font-family: "MontserratarmLight"; }
    .advertisement-add-info-container .form button.submit:hover {
      opacity: 0.8; }

@media screen and (max-width: 1680px) {
  .advertisement-add-info-container .wrapper {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .advertisement-add-info-container .section-title,
  .advertisement-add-info-container h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .advertisement-add-info-container .section-description {
    margin: 35px 0px 25px; }
  .advertisement-add-info-container .date-picker .section-title {
    font-size: 22px;
    margin-bottom: 0px; }
  .advertisement-add-info-container .date-picker .section-description {
    margin: 35px 0px 25px; }
  .advertisement-add-info-container .form .row textarea {
    height: 150px; }
  .advertisement-add-info-container .form .row .invalid-feedback {
    font-size: 12px; }
  .advertisement-add-info-container .form a.cancel,
  .advertisement-add-info-container .form button.submit {
    font-size: 14px; } }

@media screen and (max-width: 1270px) {
  .advertisement-add-info-container .wrapper {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .advertisement-add-info-container .wrapper {
    min-height: calc(100vh - 404px); }
  .advertisement-add-info-container .date-picker {
    padding: 0px; } }

@media screen and (max-width: 760px) {
  .advertisement-add-info-container .wrapper {
    min-height: calc(100vh - 612px); }
  .advertisement-add-info-container .advertisement-info-box .item .title {
    font-size: 10px; }
  .advertisement-add-info-container .advertisement-info-box .item .price {
    font-size: 11px; }
  .advertisement-add-info-container .advertisement-info-box .item .size {
    font-size: 11px; } }

@media screen and (max-width: 520px) {
  .advertisement-add-info-container .form a.cancel,
  .advertisement-add-info-container .form button.submit {
    height: 36px;
    line-height: 36px;
    padding: 0px;
    width: 113px; } }

.mobile-view .advertisement-add-info-container .form .custom-attach-file input[type="file"] {
  display: block;
  position: absolute;
  width: 100%;
  opacity: 1;
  top: 0px;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
  height: 100%; }

.advertisement-info-container {
  padding: 52px 0px;
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .advertisement-info-container .wrapper {
    max-width: 920px;
    width: 90%; }
  .advertisement-info-container .post-info-box {
    padding-left: 280px;
    box-sizing: border-box;
    width: 100%; }
  .advertisement-info-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 15px; }
  .advertisement-info-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 0px 0px 40px; }
  .advertisement-info-container .approved-advertisement-container {
    margin-bottom: 45px;
    padding-bottom: 65px;
    border-bottom: 1px solid #dcdcdc; }
    .advertisement-info-container .approved-advertisement-container .error-text {
      font-size: 14px;
      font-family: "MontserratarmSemiBold"; }
  .advertisement-info-container .submited-advertisement-container ul.pagination,
  .advertisement-info-container .approved-advertisement-container ul.pagination {
    margin-top: 50px; }
  .advertisement-info-container .submited-advertisement-container .post-item,
  .advertisement-info-container .approved-advertisement-container .post-item {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 13px;
    display: flex;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #f3f3f3; }
    .advertisement-info-container .submited-advertisement-container .post-item:last-child,
    .advertisement-info-container .approved-advertisement-container .post-item:last-child {
      border: 0px; }
    .advertisement-info-container .submited-advertisement-container .post-item:hover,
    .advertisement-info-container .approved-advertisement-container .post-item:hover {
      background-color: #f3f3f3; }
    .advertisement-info-container .submited-advertisement-container .post-item .left,
    .advertisement-info-container .approved-advertisement-container .post-item .left {
      width: 70%; }
    .advertisement-info-container .submited-advertisement-container .post-item .right,
    .advertisement-info-container .approved-advertisement-container .post-item .right {
      width: 30%;
      text-align: right; }
    .advertisement-info-container .submited-advertisement-container .post-item .cancel-button,
    .advertisement-info-container .approved-advertisement-container .post-item .cancel-button {
      box-sizing: border-box;
      text-align: center;
      display: inline-block;
      width: 130px;
      line-height: 38px;
      height: 38px;
      border-radius: 6px;
      border: 1px solid #ed1c24;
      color: #ed1c24;
      font-size: 14px;
      font-family: "MontserratarmLight";
      cursor: pointer; }
      .advertisement-info-container .submited-advertisement-container .post-item .cancel-button:hover,
      .advertisement-info-container .approved-advertisement-container .post-item .cancel-button:hover {
        background-color: #ed1c24;
        color: #fff; }
    .advertisement-info-container .submited-advertisement-container .post-item .resend-button,
    .advertisement-info-container .approved-advertisement-container .post-item .resend-button {
      box-sizing: border-box;
      text-align: center;
      display: inline-block;
      width: 130px;
      line-height: 38px;
      height: 38px;
      border-radius: 6px;
      border: 1px solid #ed1c24;
      color: #ed1c24;
      font-size: 14px;
      font-family: "MontserratarmLight";
      cursor: pointer; }
      .advertisement-info-container .submited-advertisement-container .post-item .resend-button:hover,
      .advertisement-info-container .approved-advertisement-container .post-item .resend-button:hover {
        background-color: #ed1c24;
        color: #fff; }
    .advertisement-info-container .submited-advertisement-container .post-item .status,
    .advertisement-info-container .approved-advertisement-container .post-item .status {
      width: 135px;
      display: inline-block;
      text-align: right; }
      .advertisement-info-container .submited-advertisement-container .post-item .status:before,
      .advertisement-info-container .approved-advertisement-container .post-item .status:before {
        content: attr(data-status);
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .advertisement-info-container .submited-advertisement-container .post-item .status span,
      .advertisement-info-container .approved-advertisement-container .post-item .status span {
        margin-left: 6px;
        width: 8px;
        height: 8px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%; }
      .advertisement-info-container .submited-advertisement-container .post-item .status[data-statusCode="P"] span, .advertisement-info-container .submited-advertisement-container .post-item .status[data-statusCode="F"] span,
      .advertisement-info-container .approved-advertisement-container .post-item .status[data-statusCode="P"] span,
      .advertisement-info-container .approved-advertisement-container .post-item .status[data-statusCode="F"] span {
        background-color: #ffce00; }
      .advertisement-info-container .submited-advertisement-container .post-item .status[data-statusCode="A"] span, .advertisement-info-container .submited-advertisement-container .post-item .status[data-statusCode="paid"] span,
      .advertisement-info-container .approved-advertisement-container .post-item .status[data-statusCode="A"] span,
      .advertisement-info-container .approved-advertisement-container .post-item .status[data-statusCode="paid"] span {
        background-color: #78d000; }
      .advertisement-info-container .submited-advertisement-container .post-item .status[data-statusCode="R"] span, .advertisement-info-container .submited-advertisement-container .post-item .status[data-statusCode="C"] span,
      .advertisement-info-container .approved-advertisement-container .post-item .status[data-statusCode="R"] span,
      .advertisement-info-container .approved-advertisement-container .post-item .status[data-statusCode="C"] span {
        background-color: #ed1c24; }
    .advertisement-info-container .submited-advertisement-container .post-item .image-box,
    .advertisement-info-container .approved-advertisement-container .post-item .image-box {
      width: 30%;
      float: left;
      padding-bottom: 18%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer; }
      .advertisement-info-container .submited-advertisement-container .post-item .image-box img,
      .advertisement-info-container .approved-advertisement-container .post-item .image-box img {
        display: none; }
    .advertisement-info-container .submited-advertisement-container .post-item .text-box,
    .advertisement-info-container .approved-advertisement-container .post-item .text-box {
      margin-left: 2%;
      width: 68%;
      float: right; }
    .advertisement-info-container .submited-advertisement-container .post-item .date,
    .advertisement-info-container .approved-advertisement-container .post-item .date {
      font-size: 13px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 2px; }
      .advertisement-info-container .submited-advertisement-container .post-item .date time,
      .advertisement-info-container .approved-advertisement-container .post-item .date time {
        font-family: "MontserratarmSemiBold"; }
    .advertisement-info-container .submited-advertisement-container .post-item .from-to,
    .advertisement-info-container .approved-advertisement-container .post-item .from-to {
      font-size: 13px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 2px; }
      .advertisement-info-container .submited-advertisement-container .post-item .from-to time,
      .advertisement-info-container .approved-advertisement-container .post-item .from-to time {
        font-family: "MontserratarmSemiBold"; }
    .advertisement-info-container .submited-advertisement-container .post-item .price,
    .advertisement-info-container .approved-advertisement-container .post-item .price {
      font-size: 13px;
      font-family: "MontserratarmLight";
      color: #000;
      margin-bottom: 2px; }
      .advertisement-info-container .submited-advertisement-container .post-item .price span:last-child,
      .advertisement-info-container .approved-advertisement-container .post-item .price span:last-child {
        font-family: "MontserratarmSemiBold"; }
    .advertisement-info-container .submited-advertisement-container .post-item .description,
    .advertisement-info-container .approved-advertisement-container .post-item .description {
      font-size: 13px;
      font-family: "MontserratarmLight";
      color: #000; }
    .advertisement-info-container .submited-advertisement-container .post-item .title,
    .advertisement-info-container .approved-advertisement-container .post-item .title {
      font-size: 14px;
      margin-bottom: 10px;
      font-family: "MontserratarmSemiBold";
      color: #000;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }

@media screen and (max-width: 1680px) {
  .advertisement-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .advertisement-info-container .section-title,
  .advertisement-info-container h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .advertisement-info-container .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; } }

@media screen and (max-width: 1270px) {
  .advertisement-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .advertisement-info-container {
    min-height: calc(100vh - 404px); }
    .advertisement-info-container .submited-advertisement-container .post-item .cancel-button,
    .advertisement-info-container .approved-advertisement-container .post-item .cancel-button {
      width: 110px;
      line-height: 34px;
      height: 34px;
      font-size: 12px; } }

@media screen and (max-width: 768px) {
  .advertisement-info-container .post-info-box {
    padding: 0px; } }

@media screen and (max-width: 760px) {
  .advertisement-info-container {
    min-height: calc(100vh - 612px); }
    .advertisement-info-container .submited-advertisement-container .post-item,
    .advertisement-info-container .approved-advertisement-container .post-item {
      display: block; }
      .advertisement-info-container .submited-advertisement-container .post-item .left,
      .advertisement-info-container .submited-advertisement-container .post-item .right,
      .advertisement-info-container .approved-advertisement-container .post-item .left,
      .advertisement-info-container .approved-advertisement-container .post-item .right {
        width: 100%; }
      .advertisement-info-container .submited-advertisement-container .post-item .left .image-box,
      .advertisement-info-container .submited-advertisement-container .post-item .left .text-box,
      .advertisement-info-container .approved-advertisement-container .post-item .left .image-box,
      .advertisement-info-container .approved-advertisement-container .post-item .left .text-box {
        float: none;
        width: 100%;
        margin: 0px; }
      .advertisement-info-container .submited-advertisement-container .post-item .left .text-box,
      .advertisement-info-container .approved-advertisement-container .post-item .left .text-box {
        margin: 15px 0px; }
      .advertisement-info-container .submited-advertisement-container .post-item .left .image-box,
      .advertisement-info-container .approved-advertisement-container .post-item .left .image-box {
        padding: 0px;
        background-image: none !important; }
        .advertisement-info-container .submited-advertisement-container .post-item .left .image-box img,
        .advertisement-info-container .approved-advertisement-container .post-item .left .image-box img {
          display: block;
          height: auto;
          width: 100%; }
          .advertisement-info-container .submited-advertisement-container .post-item .left .image-box img.post-inner,
          .advertisement-info-container .approved-advertisement-container .post-item .left .image-box img.post-inner {
            width: 28%; }
      .advertisement-info-container .submited-advertisement-container .post-item .right,
      .advertisement-info-container .approved-advertisement-container .post-item .right {
        text-align: center; }
      .advertisement-info-container .submited-advertisement-container .post-item .status,
      .advertisement-info-container .approved-advertisement-container .post-item .status {
        width: auto; } }

.membership-info-container {
  padding: 52px 0px;
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .membership-info-container .wrapper {
    max-width: 920px;
    width: 90%; }
  .membership-info-container .membership-info-box {
    padding-left: 280px;
    box-sizing: border-box;
    width: 100%; }
  .membership-info-container .section-title {
    font-size: 24px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 22px; }
  .membership-info-container .section-description {
    font-family: "MontserratarmLight";
    font-size: 14px;
    line-height: 24px; }
  .membership-info-container .global-info {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc; }
    .membership-info-container .global-info h1.section-title {
      margin-bottom: 20px;
      font-size: 26px; }
  .membership-info-container .active-membership-list {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dcdcdc; }
    .membership-info-container .active-membership-list .section-description {
      margin-bottom: 15px; }
    .membership-info-container .active-membership-list .section-info {
      font-family: "MontserratarmLight";
      font-size: 16px;
      color: #000;
      margin-bottom: 30px; }
      .membership-info-container .active-membership-list .section-info span {
        font-family: "MontserratarmSemiBold"; }
    .membership-info-container .active-membership-list .error-text {
      font-size: 14px;
      font-family: "MontserratarmSemiBold"; }
    .membership-info-container .active-membership-list .active-list .active-list-item {
      margin-top: 15px; }
    .membership-info-container .active-membership-list .active-list .row .item {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: "MontserratarmLight";
      color: #000;
      width: 25%;
      text-align: left; }
      .membership-info-container .active-membership-list .active-list .row .item a {
        color: #1b6be4;
        text-decoration: underline; }
      .membership-info-container .active-membership-list .active-list .row .item.last {
        text-align: right; }
      .membership-info-container .active-membership-list .active-list .row .item span {
        font-size: 14px;
        font-family: "MontserratarmLight";
        color: #000; }
        .membership-info-container .active-membership-list .active-list .row .item span:first-child {
          display: none; }
        .membership-info-container .active-membership-list .active-list .row .item span:last-child {
          font-family: "MontserratarmSemiBold"; }
  .membership-info-container .available-membership-list .available-list {
    margin-top: 35px; }
    .membership-info-container .available-membership-list .available-list .available-item {
      box-sizing: border-box;
      width: 23%;
      border: 1px solid #dcdcdc;
      float: left;
      margin-right: 2.6%;
      border-radius: 6px;
      padding: 8px 20px 15px;
      margin-bottom: 2.6%; }
      .membership-info-container .available-membership-list .available-list .available-item:nth-child(4n) {
        margin-right: 0px; }
      .membership-info-container .available-membership-list .available-list .available-item .title {
        font-size: 22px;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .membership-info-container .available-membership-list .available-list .available-item .price {
        margin: 2px 0px 60px;
        font-size: 15px;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .membership-info-container .available-membership-list .available-list .available-item .button {
        cursor: pointer;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        width: 100%;
        line-height: 38px;
        height: 38px;
        border-radius: 6px;
        border: 1px solid #ED1C24;
        color: #ED1C24;
        font-size: 14px;
        font-family: "MontserratarmLight"; }
        .membership-info-container .available-membership-list .available-list .available-item .button:hover {
          background-color: #ED1C24;
          color: #fff; }

@media screen and (max-width: 1680px) {
  .membership-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .membership-info-container .global-info h1.section-title {
    font-size: 22px;
    margin-bottom: 24px; }
  .membership-info-container .section-title {
    font-size: 20px; }
  .membership-info-container .section-description {
    font-size: 13px; }
  .membership-info-container .active-membership-list .section-info {
    font-size: 15px; }
  .membership-info-container .available-membership-list .available-list .available-item .title {
    font-size: 20px; }
  .membership-info-container .available-membership-list .available-list .available-item .price {
    font-size: 14px;
    margin: 2px 0px 40px; }
  .membership-info-container .available-membership-list .available-list .available-item .button {
    height: 32px;
    line-height: 32px;
    font-size: 13px; } }

@media screen and (max-width: 1270px) {
  .membership-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .membership-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 768px) {
  .membership-info-container .membership-info-box {
    padding: 0px; } }

@media screen and (max-width: 760px) {
  .membership-info-container {
    min-height: calc(100vh - 612px); } }

@media screen and (max-width: 720px) {
  .membership-info-container {
    min-height: calc(100vh - 573px); }
    .membership-info-container .available-membership-list .available-list .available-item {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 4%; }
      .membership-info-container .available-membership-list .available-list .available-item:nth-child(2n) {
        margin-right: 0px; } }

@media screen and (max-width: 520px) {
  .membership-info-container .active-membership-list .active-list .active-list-header {
    display: none; }
  .membership-info-container .active-membership-list .active-list .active-list-item {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc; }
  .membership-info-container .active-membership-list .active-list .row .item {
    width: 100%;
    display: block;
    margin-bottom: 5px; }
    .membership-info-container .active-membership-list .active-list .row .item:last-child {
      margin-bottom: 0px; }
    .membership-info-container .active-membership-list .active-list .row .item span:first-child {
      display: inline-block; }
    .membership-info-container .active-membership-list .active-list .row .item.last {
      text-align: left; }
  .membership-info-container .available-membership-list .available-list .available-item {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px; }
    .membership-info-container .available-membership-list .available-list .available-item:nth-child(2n) {
      margin-right: 0px; } }

.purchase-info-container, .orderstatus-info-container {
  padding: 52px 0px;
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px);
  text-align: center; }
  .purchase-info-container .info .section-title, .orderstatus-info-container .info .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 35px; }
  .purchase-info-container .info .box, .orderstatus-info-container .info .box {
    max-width: 400px;
    width: 90%;
    display: inline-block;
    background-color: #f3f3f3;
    text-align: center;
    box-sizing: border-box;
    padding: 15px 0px 25px;
    font-family: "MontserratarmSemiBold"; }
  .purchase-info-container .info .type-title, .orderstatus-info-container .info .type-title {
    font-size: 22px; }
  .purchase-info-container .info .title, .orderstatus-info-container .info .title {
    font-size: 15px;
    margin: 10px 0px; }
  .purchase-info-container .info .range, .orderstatus-info-container .info .range {
    font-family: "MontserratarmLight";
    font-size: 15px; }
  .purchase-info-container .info .price, .orderstatus-info-container .info .price {
    font-size: 15px;
    margin: 10px 0px 20px; }
  .purchase-info-container .info .button, .orderstatus-info-container .info .button {
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    width: 150px;
    line-height: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid #ED1C24;
    color: #ED1C24;
    font-size: 14px;
    font-family: "MontserratarmLight";
    cursor: pointer;
    background-color: #ED1C24;
    color: #fff; }
  .purchase-info-container .message-from-server, .orderstatus-info-container .message-from-server {
    font-family: "MontserratarmSemiBold";
    font-size: 18px;
    margin-bottom: 25px; }
  .purchase-info-container .resend-button, .purchase-info-container a.button, .orderstatus-info-container .resend-button, .orderstatus-info-container a.button {
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    width: 130px;
    line-height: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid #ED1C24;
    color: #ED1C24;
    font-size: 14px;
    font-family: "MontserratarmLight";
    cursor: pointer; }
    .purchase-info-container .resend-button:hover, .purchase-info-container a.button:hover, .orderstatus-info-container .resend-button:hover, .orderstatus-info-container a.button:hover {
      background-color: #ED1C24;
      color: #fff; }

.orderstatus-info-container .info .button {
  margin-top: 15px;
  width: auto;
  padding: 0px 15px; }

@media screen and (max-width: 1680px) {
  .purchase-info-container, .orderstatus-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .purchase-info-container .info .section-title, .purchase-info-container .info h1, .orderstatus-info-container .info .section-title, .orderstatus-info-container .info h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .purchase-info-container .info .section-description, .orderstatus-info-container .info .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; }
  .purchase-info-container .info .type-title, .orderstatus-info-container .info .type-title {
    font-size: 18px; }
  .purchase-info-container .info .title, .orderstatus-info-container .info .title {
    font-size: 14px; }
  .purchase-info-container .info .range, .orderstatus-info-container .info .range {
    font-size: 14px; }
  .purchase-info-container .info .price, .orderstatus-info-container .info .price {
    font-size: 14px; }
  .purchase-info-container .info .button, .orderstatus-info-container .info .button {
    width: 130px;
    height: 36px;
    line-height: 36px; } }

@media screen and (max-width: 1270px) {
  .purchase-info-container, .orderstatus-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .purchase-info-container, .orderstatus-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .purchase-info-container, .orderstatus-info-container {
    min-height: calc(100vh - 612px); } }

.withdraw-info-container {
  padding: 52px 0px;
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 471px); }
  .withdraw-info-container .react-datepicker-wrapper + .invalid-feedback {
    bottom: -18px !important; }
  .withdraw-info-container .react-datepicker-popper + .invalid-feedback {
    bottom: -18px !important; }
  .withdraw-info-container .react-datepicker-wrapper {
    cursor: pointer;
    width: 100%;
    background-color: #fff;
    height: 42px;
    line-height: 42px;
    box-sizing: border-box;
    font-size: 15px;
    font-family: "MontserratarmLight";
    display: block;
    letter-spacing: 0.01em;
    margin-bottom: 43px; }
    .withdraw-info-container .react-datepicker-wrapper > div {
      display: block;
      height: 100%; }
      .withdraw-info-container .react-datepicker-wrapper > div .datepicker-btn {
        padding: 0px 15px;
        height: 100%;
        border: 1px solid #dcdcdc;
        border-radius: 6px; }
        .withdraw-info-container .react-datepicker-wrapper > div .datepicker-btn.is-invalid {
          border: 1px solid #D93837; }
  .withdraw-info-container .react-datepicker {
    border: 1px solid #eaeaea;
    font-family: "MontserratarmLight";
    font-size: 12px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    overflow: hidden; }
    .withdraw-info-container .react-datepicker .react-datepicker__current-month {
      display: none; }
    .withdraw-info-container .react-datepicker .react-datepicker__header__dropdown select {
      appearance: menulist;
      font-size: 13px;
      font-family: "MontserratarmLight";
      border: 1px solid #dcdcdc;
      background-color: transparent;
      border-radius: 6px; }
  .withdraw-info-container .react-datepicker__triangle {
    display: none; }
  .withdraw-info-container .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #eaeaea; }
  .withdraw-info-container .react-datepicker__day--selected, .withdraw-info-container .react-datepicker__day--today {
    background: #eb1c24; }
    .withdraw-info-container .react-datepicker__day--selected:hover, .withdraw-info-container .react-datepicker__day--today:hover {
      background: #eb1c24; }
  .withdraw-info-container .react-datepicker__current-month {
    font-weight: initial;
    font-family: "MontserratarmSemiBold";
    font-size: 15px; }
  .withdraw-info-container .react-datepicker__header {
    border-bottom: 0px;
    background-color: #fafafa; }
  .withdraw-info-container .wrapper {
    max-width: 920px;
    width: 90%; }
  .withdraw-info-container .cell {
    text-align: center; }
    .withdraw-info-container .cell .section-title, .withdraw-info-container .cell .section-description {
      margin-bottom: 0px; }
  .withdraw-info-container .section-title {
    font-size: 26px;
    font-family: "MontserratarmLight";
    color: #000;
    text-align: center;
    margin-bottom: 15px; }
  .withdraw-info-container .section-description {
    font-size: 14px;
    font-family: "MontserratarmLight";
    color: #000;
    line-height: 24px;
    margin: 0px 0px 40px; }
  .withdraw-info-container .withdraw-info-box-container {
    position: relative;
    padding-bottom: 270px; }
    .withdraw-info-container .withdraw-info-box-container.no-padding-bottom {
      padding-bottom: 0px; }
    .withdraw-info-container .withdraw-info-box-container .earnings-list-box .error-text {
      text-align: center;
      font-family: "MontserratarmSemiBold";
      font-size: 18px; }
    .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item {
      box-sizing: border-box;
      padding: 13px;
      transition: all .3s ease-in-out;
      border-bottom: 1px solid #f3f3f3; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .left {
        width: 65%;
        float: left; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .right {
        float: right;
        width: 135px;
        padding-top: 32px; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .date {
        font-size: 13px;
        color: #000;
        font-family: "MontserratarmLight"; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .title {
        margin-top: 5px;
        font-size: 13px;
        font-family: "MontserratarmSemiBold";
        color: #000; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item a.link {
        font-size: 13px;
        text-decoration: underline;
        color: #000;
        font-family: "MontserratarmLight"; }
        .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item a.link:hover {
          color: #636363; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .bottom {
        margin-top: 10px; }
        .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .bottom .view-count {
          font-size: 14px;
          font-family: "MontserratarmLight";
          display: inline-block;
          vertical-align: top;
          color: #000;
          margin-right: 20px; }
          .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .bottom .view-count span {
            display: inline-block;
            vertical-align: top; }
            .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .bottom .view-count span svg {
              margin-top: 4.2px;
              margin-right: 5px;
              display: block;
              width: 15px;
              height: auto;
              fill: #565656; }
        .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item .bottom .price {
          font-size: 14px;
          font-family: "MontserratarmLight";
          display: inline-block;
          vertical-align: top;
          color: #000; }
      .withdraw-info-container .withdraw-info-box-container .earnings-list-box .earnings-item:hover {
        background-color: #f3f3f3; }
    .withdraw-info-container .withdraw-info-box-container .left-section {
      padding-right: 300px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row {
        position: relative;
        margin-bottom: 25px; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .text {
          font-size: 12px;
          font-family: "MontserratarmSemiBold";
          color: #000;
          margin-bottom: 4px; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .title {
          font-size: 17px;
          font-family: "MontserratarmLight";
          color: #000; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .required-text {
          font-family: "MontserratarmLight"; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input {
          width: 100%;
          line-height: 42px;
          height: 42px;
          box-sizing: border-box;
          padding: 0px 18px;
          border: 1px solid  #dcdcdc;
          border-radius: 6px;
          font-size: 15px;
          font-family: "MontserratarmLight";
          color: #000;
          margin-bottom: 18px;
          transition: border 0.3s ease-in-out; }
          .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input::-webkit-input-placeholder {
            color: #BCBEC0; }
          .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input:-moz-placeholder {
            color: #BCBEC0; }
          .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input::-moz-placeholder {
            color: #BCBEC0; }
          .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input:-ms-input-placeholder {
            color: #BCBEC0; }
          .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input.is-invalid {
            border: 1px solid  #D93837; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row.dropdown-row {
          margin-bottom: 43px; }
          .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row.dropdown-row .invalid-feedback {
            bottom: -18px; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .invalid-feedback {
          position: absolute;
          color: #D93837;
          font-size: 14px;
          bottom: 0px;
          font-family: "MontserratarmLight"; }
        .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row:last-child {
          margin-bottom: 0px; }
    .withdraw-info-container .withdraw-info-box-container .right-section {
      position: absolute;
      right: 0;
      top: 0px;
      height: calc(100% - 18px);
      -ms-flex: 1;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      width: 250px; }
      .withdraw-info-container .withdraw-info-box-container .right-section .section-box {
        box-sizing: border-box;
        width: 100%;
        position: sticky;
        position: -webkit-sticky;
        left: 0px;
        top: 196px;
        text-align: center;
        padding: 20px 15px;
        border: 1px solid #f3f3f3;
        border-radius: 6px; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .title {
          font-family: "MontserratarmSemiBold";
          font-size: 13px;
          color: #000; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .price {
          font-family: "MontserratarmSemiBold";
          font-size: 32px;
          color: #000; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row {
          margin-top: 25px; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .text {
            font-family: "MontserratarmLight";
            font-size: 14px;
            color: #000;
            text-align: left;
            margin-bottom: 5px; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap {
            position: relative;
            padding: 0px 0px 18px 0px;
            margin-bottom: 25px; }
            .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input {
              width: 100%;
              height: 38px;
              box-sizing: border-box;
              padding: 0px 25px 0px 15px;
              border: 1px solid  #dcdcdc;
              border-radius: 6px;
              font-size: 15px;
              font-family: "MontserratarmLight";
              color: #000;
              transition: border 0.3s ease-in-out; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input::-webkit-input-placeholder {
                color: #BCBEC0; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input:-moz-placeholder {
                color: #BCBEC0; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input::-moz-placeholder {
                color: #BCBEC0; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input:-ms-input-placeholder {
                color: #BCBEC0; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input.is-invalid {
                border: 1px solid  #D93837; }
            .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap span {
              position: absolute;
              display: inline-block;
              font-family: "MontserratarmLight"; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap span.sign {
                right: 15px;
                font-size: 16px;
                color: #AAAAAA;
                top: 8px; }
              .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap span.min-amount {
                font-size: 12px;
                display: block;
                text-align: right;
                width: 100%;
                right: 0px;
                bottom: 0px; }
                .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap span.min-amount.is-invalid {
                  color: #D93837; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item {
          margin-bottom: 10px; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item .text, .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item .price {
            display: block;
            float: left;
            width: 50%;
            font-size: 14px;
            color: #000; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item .text {
            text-align: left;
            font-family: "MontserratarmLight"; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item .price {
            font-family: "MontserratarmSemiBold";
            text-align: right; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item:last-child {
            margin-bottom: 0px; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .total {
          margin-top: 30px; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .button-disabled {
          border: 0px;
          border-radius: 6px;
          padding: 0px 48px;
          color: #fff;
          display: inline-block;
          cursor: not-allowed;
          color: #d4d4d4;
          border: 1px solid #d4d4d4;
          font-size: 16px;
          border-radius: 6px;
          line-height: 38px;
          height: 38px;
          margin-top: 25px;
          font-family: "MontserratarmLight"; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .button {
          border: 0px;
          border-radius: 6px;
          padding: 0px 48px;
          color: #fff;
          display: inline-block;
          cursor: pointer;
          background-color: #ED1C24;
          font-size: 16px;
          border-radius: 6px;
          line-height: 38px;
          height: 38px;
          margin-top: 25px;
          font-family: "MontserratarmLight"; }
          .withdraw-info-container .withdraw-info-box-container .right-section .section-box .button:hover {
            opacity: .8; }
        .withdraw-info-container .withdraw-info-box-container .right-section .section-box .error-text {
          font-size: 12px;
          color: #D93837;
          font-family: "MontserratarmLight";
          margin-top: 5px; }

@media screen and (max-width: 1680px) {
  .withdraw-info-container {
    min-height: calc(100vh - 465px); } }

@media screen and (max-width: 1440px) {
  .withdraw-info-container .react-datepicker-wrapper {
    line-height: 38px;
    height: 38px;
    font-size: 13px;
    margin-bottom: 38px; }
  .withdraw-info-container .section-title, .withdraw-info-container h1 {
    font-size: 22px;
    margin-bottom: 24px; }
  .withdraw-info-container .section-description {
    font-size: 13px;
    margin: 0px 0px 30px; }
  .withdraw-info-container .withdraw-info-box-container .left-section {
    padding-right: 280px; }
    .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row {
      margin-bottom: 20px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row.dropdown-row {
        margin-bottom: 38px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row input {
        height: 38px;
        line-height: 38px;
        font-size: 13px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .text {
        font-size: 11px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .title {
        font-size: 15px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row button {
        font-size: 14px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row a.forgot {
        font-size: 11px; }
      .withdraw-info-container .withdraw-info-box-container .left-section .form-box .row .invalid-feedback {
        font-size: 12px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .text {
    font-size: 12px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .input-row .wrap input {
    height: 36px;
    line-height: 36px;
    font-size: 13px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item .text, .withdraw-info-container .withdraw-info-box-container .right-section .section-box .info-box .item .price {
    font-size: 12px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .price {
    font-size: 28px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .title {
    font-size: 12px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .wrap-box {
    margin-top: 10px; }
    .withdraw-info-container .withdraw-info-box-container .right-section .section-box .wrap-box .item {
      margin-bottom: 10px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .button {
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    padding: 0px 38px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box .extend {
    font-size: 12px; }
  .withdraw-info-container .withdraw-info-box-container .right-section .section-box a.link {
    font-size: 12px; } }

@media screen and (max-width: 1270px) {
  .withdraw-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 1024px) {
  .withdraw-info-container {
    min-height: calc(100vh - 404px); } }

@media screen and (max-width: 760px) {
  .withdraw-info-container {
    min-height: calc(100vh - 612px); }
    .withdraw-info-container .withdraw-info-box-container .left-section {
      padding: 0px; }
    .withdraw-info-container .withdraw-info-box-container .right-section {
      width: 280px;
      position: relative;
      top: inherit;
      right: inherit;
      height: auto;
      margin: 10px auto; } }
